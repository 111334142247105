import { animate, state, style, transition, trigger
       } from '@angular/animations';

import { Component, ElementRef, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';

import { StatisticMarksman, Team, WhitsunEvent } from '@config/models';
import { WhitsunService } from '../whitsun.service';

@Component({
  selector: 'display-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed',
            style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed',
                 animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class HistoryComponent
{
  @ViewChild('search') public search: ElementRef;

  public loading: boolean = false;
  public expandedElement: any;
  public lastSearch: string = '';

  public teamEntries: MatTableDataSource<any>;
  public marksmanEntries: MatTableDataSource<any>;

  public latestEvent: WhitsunEvent = undefined;
  public pdfFileUrlsPrefix: string = 'https://files.sg-floss.de/pfingsten/2024';

  public constructor(private whitsunService: WhitsunService)
  {
  }

  public isExpansionDetailRow(i: number, row: any): boolean
  {
    return row.hasOwnProperty('detailRow');
  }

  public async startSearch(): Promise<void>
  {
    this.loading = true;

    const entries =
        await this.whitsunService.search(this.search.nativeElement.value);
    this.lastSearch = this.search.nativeElement.value;

    this.teamEntries = this.createDataSource(entries.teams);
    this.marksmanEntries = this.createDataSource(entries.marksmen);

    this.loading = false;
  }

  public searchDisabled(): boolean
  {
    return !this.search || this.search.nativeElement.value.length < 3;
  }

  private createDataSource(data: Array<Team|StatisticMarksman>):
      MatTableDataSource<Team|StatisticMarksman>
  {
    const rows = [];
    data.forEach(element => rows.push(element, { detailRow: true, element }));
    return new MatTableDataSource(rows);
  }
}
