import { Component, Input } from '@angular/core';
import { Team, StatisticMarksman } from '@config/models';
import Chart from 'chart.js/auto';

import { HistoryDetailsComponent } from './details.component';

@Component({
  selector: 'team-details',
  templateUrl: './team-details.component.html',
  styleUrls: [ './details.component.scss', ]
})
export class TeamDetailsComponent extends HistoryDetailsComponent
{
  @Input() public instance: Team = undefined;
  public marksmen: Array<any> = [];
  public displayLegend: boolean = false;

  public ngAfterViewInit(): void
  {
    const data = this.processData();
    const datasets = [{
        data: data.points,
        backgroundColor: this.colors,
    }];
    this.renderChart(this.chartName, data.labels, datasets);
  }

  protected processData(): any
  {
    const dataPoints = {points: [], labels: []};

    const byEvent = {};
    for (const result of this.instance.results) {
      result.marksman.results.push(result.result);

      if (!byEvent[result.eventId]) {
        byEvent[result.eventId] = {};
        byEvent[result.eventId].total = result.result;
        byEvent[result.eventId].marksmen = [];
        byEvent[result.eventId].marksmen.push(result.marksman);
      }
      else {
        byEvent[result.eventId].total += result.result;
        byEvent[result.eventId].marksmen.push(result.marksman);
      }
    }

    for (const eventId of Object.keys(byEvent)) {
      const marksmen = [];
      for (const marksman of byEvent[eventId].marksmen) {
        marksmen.push(marksman);
      }
      this.marksmen.push({
          year: eventId.replace(/pfingsten-/, ''),
          marksmen
      });

      dataPoints.points.push(byEvent[eventId].total);
      dataPoints.labels.push(eventId.replace(/pfingsten-/, ''));
    }

    console.log(dataPoints);
    return dataPoints;
  }
}
