import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatModule } from '@saikin/mat.module';

// Auth
import { AuthCanDoDirective } from './auth/auth.directive';
import { AuthService } from './auth/auth.service';

// Components
import { CnstAutoComboboxComponent
} from './components/auto-combobox/auto-combobox.component';
import { SearchFieldComponent
} from './components/search-field/search-field.component';
import { CnstButtonSectionComponent
} from './components/button-section/button-section.component';
import { CnstButtonExportComponent
} from './components/button-export/button-export.component';
import { CnstCarouselComponent
} from './components/carousel/carousel.component';
import { CnstCarouselContainer
} from './components/carousel/carousel-container.directive';
import { CnstSignaturePadComponent
} from './components/signature-pad/signature-pad.component';
import { CnstMatDatepickerComponent
} from './components/cnst-datepicker/cnst-datepicker.component';
import { CnstMatSelectComponent
} from './components/cnst-select/cnst-select.component';
import { SaikinEntryView } from './components/_base/entry';

import { StartscreenComponent
} from './components/startscreen/startscreen.component';
import { CnstSelectOptionsComponent
} from './components/select-options/select-options.component';

import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipDirective } from './components/tooltip/tooltip.directive';

// Directives
import { CdkDetailRowDirective } from './directives/cdk-detail-row.directive';
import { CnstMiddleClickDirective } from './directives/middleclick.directive';
import { CnstMatTableResponsiveDirective
} from './directives/mat-table-responsive.directive';

// Pipes
import { CnstFilterPipe } from './pipes/filter.pipe';
import { FormatNumberRange } from './pipes/format-number-range.pipe';
import { SafeSanitizePipe } from './pipes/safe-sanitize.pipe';
import { ToStringPipe } from './pipes/to-string.pipe';

@NgModule({
  declarations: [
    // Components
    CnstAutoComboboxComponent,
    CnstButtonSectionComponent, CnstButtonExportComponent,
    CnstCarouselComponent, CnstCarouselContainer,
    CnstSignaturePadComponent,
    SearchFieldComponent,
    SaikinEntryView,
    CnstSelectOptionsComponent,
    CnstMatDatepickerComponent,
    CnstMatSelectComponent,
    StartscreenComponent,
    TooltipComponent, TooltipDirective,

    // Directives
    AuthCanDoDirective,
    CdkDetailRowDirective,
    CnstMiddleClickDirective,
    CnstMatTableResponsiveDirective,

    // Pipes
    CnstFilterPipe,
    FormatNumberRange,
    SafeSanitizePipe,
    ToStringPipe,
  ],
  imports: [
    FormsModule, ReactiveFormsModule, RouterModule,
    MatModule,
  ],
  exports: [
    // Components
    CnstAutoComboboxComponent,
    CnstButtonSectionComponent, CnstButtonExportComponent,
    CnstCarouselComponent, CnstCarouselContainer,
    CnstSignaturePadComponent,
    SearchFieldComponent,
    SaikinEntryView,
    CnstSelectOptionsComponent,
    CnstMatDatepickerComponent,
    CnstMatSelectComponent,
    StartscreenComponent,

    // Directives
    AuthCanDoDirective,
    CdkDetailRowDirective,
    CnstMiddleClickDirective,
    CnstMatTableResponsiveDirective,
    TooltipDirective,

    // Pipes
    CnstFilterPipe,
    FormatNumberRange,
    SafeSanitizePipe,
    ToStringPipe,

    MatModule
  ],
  providers: [
    AuthService,
  ]
})
export class SaikinModule { }
