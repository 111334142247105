import { ChangeDetectionStrategy, ContentChildren, Component, EventEmitter,
         Input, AfterViewInit, Output, QueryList
} from '@angular/core';

import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cnst-select-options',
  template: `
    <mat-form-field theme="control"
                    [appearance]="appearance" floatLabel="always"
                    style="width: 100%;
                           font-size: {{ theme === 'control' ?
                                            'inherit' : '1rem !important;' }}">
      <input matInput [hidden]="true">
      <mat-label>{{ label }}</mat-label>
      <ng-content></ng-content>
    </mat-form-field>
  `,
})
export class CnstSelectOptionsComponent implements AfterViewInit
{
  @Input() appearance = 'default';
  @Input() theme = 'control';
  @Input() label: string = undefined;

  @Input() value: Array<any>;
  @Output() valueChange: EventEmitter<Array<any>> =
    new EventEmitter<Array<any>>();

  @Output() removed: EventEmitter<any> = new EventEmitter<any>();
  @Output() added: EventEmitter<any> = new EventEmitter<any>();

  @ContentChildren(MatCheckbox, { descendants: true })
  public options: QueryList<MatCheckbox>;

  public ngAfterViewInit(): void
  {
    this.options.forEach((element) => {
      element.change.subscribe((value) => {
        this.changeEvent(element.value, value.checked);
      });
    });

    setTimeout(() => {
      this.options.forEach((element) => {
        element.checked = this.value.indexOf(element.value) > -1;
      });
    }, 20);
  }

  public changeEvent(value: any, added: boolean): void
  {
    const idx = this.value.indexOf(value);
    if (added) {
      this.value.push(value);
      this.added.emit(value);
    }
    else if (idx > -1) {
      this.value.splice(idx, 1);
      this.removed.emit(value);
    }

    this.valueChange.emit(this.value);
  }
}
