import { Component, EventEmitter, Input, Output, QueryList
} from '@angular/core';

@Component({
  selector: 'button-export',
  template: `
    <ng-container *ngIf="types.length === 1">
      <button mat-flat-button color="primary" class="lone"
              [disabled]="disabled"
              (click)="selected(types.length)">
        Download (.{{ types[0] }})
      </button>
    </ng-container>

    <ng-container *ngIf="types.length > 1">
      <button mat-flat-button color="primary"
              [disabled]="disabled"
              [matMenuTriggerFor]="downloadMenu">
        Download als
      </button>
      <mat-menu #downloadMenu="matMenu" xPosition="before">
        <button mat-menu-item *ngFor="let type of types"
                (click)="clicked(type)"
                [disabled]="disabled"
        >.{{ type }}</button>
      </mat-menu>
    </ng-container>
  `,
  styles: [ ]
})
export class CnstButtonExportComponent
{
  @Input() types: string[] = [ 'csv' ];
  @Input() disabled = false;
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();

  public clicked(type: string): void
  {
    this.selected.emit(type);
  }
}
