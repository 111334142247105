import { AfterViewInit, ChangeDetectionStrategy, ContentChildren,
         Component, ElementRef, Input, QueryList, ViewChild
} from '@angular/core';
import { CnstCarouselContainer } from './carousel-container.directive';

import { sleep } from '@saikin/util';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cnst-carousel',
  template: `<div class="cnst-carousel" #containerWrapper>
    <ng-content></ng-content>
  </div>`,
  styleUrls: [ './carousel.component.scss' ]
})
export class CnstCarouselComponent implements AfterViewInit
{
  @ContentChildren(CnstCarouselContainer)
  public containers: QueryList<CnstCarouselContainer>;

  @ViewChild('containerWrapper') public containerWrapper: ElementRef;

  @Input() public activeIndex = 0;

  public ngAfterViewInit(): void
  {
    this.containers.toArray()[this.activeIndex].show('right');

    this.containers.forEach((element, index) => {
      const resizeObserver = new ResizeObserver(() => {
        this.setContainerHeight(index);
      });
      resizeObserver.observe(element.elementRef.nativeElement);

      element.swiped.subscribe((direction) => {
        if (direction) {
          const newIndex =
            Math.max(0, Math.min(this.containers.length - 1,
             this.activeIndex + ( direction === 'left' ? -1 : 1 )));
          this.jumpTo(newIndex);
          this.setContainerHeight(this.activeIndex);
        }
      });
    });

    this.jumpTo(this.activeIndex);
  }

  public reload(): void
  {
    this.containers.toArray()[this.activeIndex].show('right');
    sleep(200).then(() => { this.setContainerHeight(this.activeIndex); });
  }

  public previous(): boolean
  {
    const previous = Math.max(0, this.activeIndex - 1);
    if (previous === this.activeIndex) {
      return false;
    }
    else {
      this.jumpTo(previous);
      return true;
    }
  }

  public next(): boolean
  {
    const next = Math.min(this.containers.length - 1, this.activeIndex + 1);
    if (next === this.activeIndex) {
      return false;
    }
    else {
      this.jumpTo(next);
      return true;
    }
  }

  public jumpTo(newIndex: number): void
  {
    if (newIndex !== this.activeIndex) {
      if (this.activeIndex > newIndex) {
        this.containers.toArray()[this.activeIndex].hide('right');
        this.containers.toArray()[newIndex].show('left');
      }
      else {
        this.containers.toArray()[this.activeIndex].hide('left');
        this.containers.toArray()[newIndex].show('right');
      }

      this.activeIndex = newIndex;
    }
    sleep(100).then(() => { this.setContainerHeight(this.activeIndex); });
  }

  private setContainerHeight(containerIndex: number): void
  {
    const height = this.containers.toArray()[containerIndex].getHeight() + 'px';
    this.containerWrapper.nativeElement.style.setProperty('height', height);
  }
}
