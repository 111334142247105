import { environment } from '@env';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { User } from '@config/models';
import { userPermissions } from '@config/permissions';
import { HttpHelper } from '@saikin/http';

@Injectable({ providedIn: 'root' })
export class AuthService
{
  public customerLogin: string = undefined;
  public currentUserSubject: BehaviorSubject<User>;
  private currentTokenSubject: BehaviorSubject<string>;
  private http: HttpHelper;

  constructor(@Inject(HttpClient) private httpClient: HttpClient)
  {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser')));

    this.currentTokenSubject = new BehaviorSubject<string>(
      localStorage.getItem('authorizationToken'));

    this.http = new HttpHelper(this.httpClient, environment.serverHostAddress);
  }

  public canDo(permission: string): boolean
  {
    const _permissions = userPermissions[this.currentUser?.role] || [];
    return _permissions.indexOf(permission) > -1;
  }

  public get currentUser(): User
  {
    return this.currentUserSubject.value;
  }

  public set currentUser(user: User)
  {
    this.storeCredentials(user, this.token);
  }

  public get token(): string
  {
    return this.currentTokenSubject.value;
  }

  public async login(credentials: any): Promise<void|string>
  {
    const payload = !credentials.hash
      ? { username: credentials.username, password: credentials.password }
      : { username: credentials.username,
          hash: credentials.hash, token: credentials.tfaToken.join('') };
    const url = '/auth' + (!credentials.hash ? '/login' : '/verify');

    const response = await this.http
      .post(url, payload, {'Content-Type': 'application/json'},
            { responseType: 'text' });

    if (response.status === 200) {
      const token = response.body;

      if (token) {
        await this.loginWithToken(token);
      }
    }
    // TFA bei StatusCode 206
    else {
      return response.body;
    }
  }

  public async loginWithToken(token: string): Promise<boolean>
  {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    };

    this.currentTokenSubject.next(token); // TODO: why?

    try {
      const response = await this.http.get('/auth', headers);
      if (response) {
        const user = User.fromResponse(response.body);
        user.etag = response.headers.get('etag').replace(/"/g, '');
        this.storeCredentials(user, token);
        return true;
      }
    }
    catch (e) {
      return false;
    }
  }

  public async checkTokenValidity(): Promise<boolean>
  {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token
    };

    try {
      await this.http.head('/auth', headers);
      return true;
    }
    catch (e) {
      return false;
    }
  }

  public async changePassword(user: User): Promise<User>
  {
    const payload = { password: user.password };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token,
      'If-Match': user.etag
    };

    const response =
        await this.http.patch('/auth', payload, headers,
                              { responseType: 'text' });
    user.etag = response.headers.get('etag').replace(/"/g, '');

    this.storeCredentials(user, response.body);

    return user;
  }

  public async passwordForgotten(email: string): Promise<void>
  {
    await this.http.put('/auth/password_forgotten',
                        { username: email },
                        {'Content-Type': 'application/json'});
  }

  public logout(): void
  {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token
    };
    this.http.post('/auth/logout', { token: this.token }, headers);

    // remove user from local storage to log user out
    localStorage.removeItem('authorizationToken');
    this.currentTokenSubject.next(undefined);
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(undefined);
  }

  public getHttpClient(): HttpClient
  {
    return this.httpClient;
  }

  private storeCredentials(user: User, token: string): void
  {
    localStorage.setItem('authorizationToken', token);

    this.currentTokenSubject.next(token);
    this.currentUserSubject.next(user);
  }
}
