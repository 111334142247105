import { Injectable } from '@angular/core';

import { SaikinService } from '@saikin/saikin.service';
import { WhitsunEvent, Team, Marksman, StatisticMarksman } from '@config/models';

@Injectable()
export class WhitsunService extends SaikinService
{
  protected baseSuffix: string = '/pfingsten';
  public eventYear: number;
  public events: Array<WhitsunEvent>;


  public async getEvents(): Promise<Array<WhitsunEvent>>
  {
    if (!this.events) {
      const response = await this.http.get('/pfingsten/events', this.getHeaders());
      this.events = response.body.map(e => WhitsunEvent.fromResponse(e));
    }
    return this.events;
  }

  public async getLatestEvent(revealed: boolean = false): Promise<WhitsunEvent>
  {
    const events = await this.getEvents();
    const tmp = { value: undefined, event: undefined };
    const now = new Date();

    for (let event of events) {
      const date = new Date(revealed ? event.revealDate : event.startDate);
      if (!tmp.value || tmp.value < date) {
        tmp.value = date;
        tmp.event = event;
      }
    }

    return tmp.event;
  }

  public async createTeam(team: Team, registerData: any): Promise<Team>
  {
    const payload = {
        ...team.toRequest(),
        ...registerData
    };

    const headers = {
      ...this.getHeaders(),
      ...this.getEventYearHeader(),
    };
    const path = '/pfingsten/teams?anonymous=1';
    const response = await this.http.post('/pfingsten/teams', payload, headers);

    return team;
  }

  public getEventYearHeader(): { [header:string]: string }
  {
    this.eventYear = parseInt(localStorage.getItem('pfingsten-event'), 10);
    if (!this.eventYear) {
      this.eventYear = new Date().getFullYear();
    }
    return { 'X-event': 'pfingsten-' + String(this.eventYear) };
  }

  public async search(search: string): Promise<any>
  {
    const response = await this.http
        .get('/pfingsten/reports/search?q=' + search, this.getHeaders());
    const body = response.body;

    body['marksmen'] = body['marksmen'].map(m => StatisticMarksman.fromResponse(m));
    body['teams'] = body['teams'].map(t => Team.fromResponse(t));

    return body;
  }
}
