import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Input,
         TemplateRef, ViewContainerRef, Output, EventEmitter
} from '@angular/core';

@Directive({
  selector: '[cdkDetailRow]'
})
export class CdkDetailRowDirective implements AfterViewInit
{
  @Output() ckdRowSelected: EventEmitter<any> = new EventEmitter<any>();

  private row: any;
  private tRef: TemplateRef<any>;
  private opened: boolean;
  private triggerClass: string = undefined;

  @HostBinding('class.expanded')
  @HostBinding('class.selected')
  get expended(): boolean
  {
    return this.opened;
  }

  @Input()
  set cdkDetailRow(value: any)
  {
    if (value !== this.row) {
      this.row = value;
      // this.render();
    }
  }

  @Input()
  set cdkDetailRowTriggerClass(value: string)
  {
    this.triggerClass = value;
  }

  @Input('cdkDetailRowTpl')                           // eslint-disable-line
  set template(value: TemplateRef<any>)
  {
    if (value !== this.tRef) {
      this.tRef = value;
      // this.render();
    }
  }

  constructor(public vcRef: ViewContainerRef, private el: ElementRef)
  {
    this.el.nativeElement.classList.add('cdk-detail-row-trigger');
  }

  public ngAfterViewInit(): void
  {
    this.el.nativeElement.classList.remove('cdk-detail-row-trigger');
    this.el.nativeElement.querySelectorAll('.' + this.triggerClass)
      .forEach((e) => {
        e.classList.add('cdk-detail-row-trigger');
      });
  }

  @HostListener('click', ['$event'])
  public onClick(): void
  {
    if (!this.triggerClass ||
        (<any> event.target).classList.contains(this.triggerClass) ||
        (<any> event.target).closest('.' + this.triggerClass)) {
      this.toggle();
    }
  }

  public toggle(): void
  {
    if (this.opened) {
      this.vcRef.clear();
    } else {
      this.render();
    }

    this.el.nativeElement
      .querySelectorAll('.cdk-detail-row-trigger.with-arrow')
      .forEach((e) => {
        e.classList.remove(this.opened ? 'up' : 'down');
        e.classList.add(this.opened ? 'down' : 'up');

        if (this.opened) {
          e.classList.add('selected');
        }
        else {
          e.classList.remove('selected');
        }
      });

    this.opened = this.vcRef.length > 0;
    this.ckdRowSelected.emit(this.opened ? this.row : undefined);
  }

  private render(): void
  {
    this.vcRef.clear();
    if (this.tRef && this.row) {
      this.vcRef.createEmbeddedView(this.tRef, { $implicit: this.row });
    }
  }
}
