<section class="cnst-theme_section">
  <div class="cnst-theme_section-header">
    <div class="cnst-row justify-space-between align-center">
      <div class="cnst-col auto">
        <h2 class="mat-title cnst-theme_section-title">
          Einladung
        </h2>
      </div>
    </div>
  </div>

  <div class="cnst-theme_section-body">
    Verehrter Vereinsvorstand, lieber Sportsfreund!<br />

    <p>
      Unsere digitalen Stände sind einsatzbereit und wir wollen euch zum
      ersten Pfingstschießen auf diesen Ständen einladen.
    </p>

    Das Schützenheim ist für euch an folgenden Tagen
    ab <strong>19:00 Uhr</strong> geöffnet:
    <br />

    <ul>
      <li *ngFor="let date of eventDates">
        {{ date | date: 'EEEE, dd.MM.' }}
      </li>
    </ul>

    <p>
      Per Email unter info@sg-floss.de vorgenommen werden.<br />
      Teilt uns am Besten dabei gleich auch mit, wer in welcher Mannschaft teilnimmt.
    </p>
  </div>

  <div class="cnst-theme_section-body">
    <h3 class="mat-subheading-2 cnst-theme_section-body-title">
      Glücksschießen
    </h3>

    <p>
      Das Glücksschießen läuft wird dieses mal nicht mit Kuvers realisiert.
      Stattdessen haben wir uns etwas neues ausgedacht! Seid gespannt, was
      unsere digitalen Stände zu bieten haben!
      <br />
      Die Preise für dieses Jahr sind die folgenden:
    </p>

    <ol>
      <li>100,- € Gutschein vom Metzgermeister Meierhöfer</li>
      <li> 75,- € Gutschein vom Metzgermeister Meierhöfer</li>
      <li> 50,- € Gutschein vom Metzgermeister Meierhöfer</li>
    </ol>

    <i>
      Zudem gibt es noch entsprechend der Teilnehmerzahl weitere ausgesuchte
      Preise zu gewinnen.
    </i>
  </div>

  <div class="cnst-theme_section-body">
    <h3 class="mat-subheading-2 cnst-theme_section-body-title">
      Pokalschießen
    </h3>
    <p>
      Beim sportlichen Mannschafts-Wettkampf wird um den
      <strong>Raiffeisen-Wanderpokal</strong> geschossen.<br />

      Eine Mannschaft besteht aus 4 Schützen,
      wobei jeder Schütze 10 Schuss abzugeben hat.<br />

      Die drei ersten Siegermannschaften erhalten jeweils einen Pokal,
      den sie ihr Eigen nennen können.<br />

      Der Raiffeisen-Wanderpokal geht erst nach einer ununterbrochenen
      Siegesserie von 3 Jahren fest in das Eigentum des betreffenden Vereins
      über.
    </p>

    <p>
      Die Anmeldegebühr für eine Mannschaft beträgt 15,- €.<br />
      Der Verein, der die größte Anzahl an Schützen an den Schießstand bringt,
      erhält von uns ein besonderes Präsent: <strong>20 Liter Bier.</strong>
    </p>

    <p>
      Zudem wird an der Preisverleihung aus den anwesenden
      Teilnehmermannschaften ein
      <strong>Gutschein über 25.- € vom Gut Plankenhammer</strong> verlost.
    </p>

    <u>Wichtige Hinweise:</u>
    <ul>
      <li>
        schießberechtigt sind all jene Personen,
        welche nicht aktive Schützen sind, bzw. mindestens drei Jahre
        nicht mehr für einen Schützenverein aktiv geschossen haben
      </li>
      <li>
        ein Schütze darf in einem Verein / Gruppierung nur in
        <u>einer</u> Mannschaft schießen
      </li>
      <li>
        ein Schütze darf für mehrere <u>verschiedene</u> Vereine / Gruppierungen
        antreten
      </li>
    </ul>
  </div>

  <div class="cnst-theme_section-body">
    <h3 class="mat-subheading-2 cnst-theme_section-body-title">
      Preisverleihung
    </h3>

    <p>
      Die Preisverteilung findet am
      <strong>{{ ceremonyDate | date: 'dd.MM.YY' }} um
              {{ ceremonyDate | date: 'HH:mm' }} Uhr</strong>
      im Schützenheim statt.<br />

      <strong>
        Gewinne verfallen, sofern der Preisgewinner bzw. deren Vertretung
        nicht anwesend ist.
      </strong>
    </p>
  </div>
</section>
