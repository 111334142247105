import { Component, Input } from '@angular/core';
import { StatisticMarksman } from '@config/models';

import { HistoryDetailsComponent } from './details.component';

@Component({
  selector: 'marksman-details',
  templateUrl: './marksman-details.component.html',
  styleUrls: [ './details.component.scss', ]
})
export class MarksmanDetailsComponent extends HistoryDetailsComponent
{
  @Input() public instance: StatisticMarksman = undefined;

  protected processData(): any
  {
    const byTeam = {};
    for (const result of this.instance.results) {
      if (!byTeam[result.team.name]) {
        byTeam[result.team.name] = [];
      }

      byTeam[result.team.name].push({
        y: result.result,
        label: parseInt(result.team.eventId.replace(/pfingsten-/, ''), 10),
      });
    }

    for (const idx of Object.keys(byTeam)) {
      const years = byTeam[idx].map(i => parseInt(i.label, 10));

      for (let i = 2012; i < (new Date()).getFullYear(); i++) {
        if (years.indexOf(i) === -1) {
          byTeam[idx].push({ y: 0, label: i });
        }
      }
      byTeam[idx].sort((a, b) => parseInt(a.label, 10) - parseInt(b.label, 10));
    }

    return byTeam;
  }
}
