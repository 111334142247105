import { Directive, EventEmitter, HostListener, Input, Output
} from '@angular/core';

@Directive({
  selector: '[matTableResponsive]'
})
export class CnstMatTableResponsiveDirective
{
  @Output() matTableResponsiveDisplay: EventEmitter<string[]> =
    new EventEmitter<string[]>();

  private screenWidth: number;
  private widthColumnsMapping: any = {};

  @Input()
  set matTableResponsive(value: any)
  {
    if (value !== this.widthColumnsMapping) {
      this.widthColumnsMapping = value;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.setColumns();
  }

  constructor()
  {
    this.screenWidth = window.innerWidth;
    this.setColumns();
  }

  public apply(): void
  {
    this.screenWidth = window.innerWidth;
    this.setColumns();
  }

  private setColumns(): void
  {
    let columns = this.widthColumnsMapping.default;

    for (const width of Object.keys(this.widthColumnsMapping)) {
      if (width === 'default') {
        continue;
      }
      if (!isNaN(Number(width)) && this.screenWidth < Number(width)) {
        columns = this.widthColumnsMapping[width];
        break;
      }
    }

    this.matTableResponsiveDisplay.emit(columns);
  }
}
