import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@saikin/auth/auth.service';

@Component({
  selector: 'startscreen',
  templateUrl: 'startscreen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./startscreen.component.scss']
})
export class StartscreenComponent
{
  public logoUrl = 'assets/images/brand.png';

  @Input() customWidth = 'default';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  )
  {
    // redirect to dashbboard if already logged in
    if (this.authService.currentUser) {
      this.router.navigate(['/']);
    }
  }
}
