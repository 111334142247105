<form>
  <mat-form-field theme="control"
                  [appearance]="appearance"
                  [floatLabel]="appearance === 'standard' ? 'never' : 'always'"
                  class="searchbar"
                  style="width:100%">
    <input matInput type="text" #searchField
           placeholder="Suche"
           [formControl]="dropdownControl"
           [matAutocomplete]="auto"
           (input)="updateList()"
           (change)="$event.value === undefined ? emitSelection(undefined) : ''"
           (click)="displayList()"
           aria-label="Assignee"
    />
    <mat-icon *ngIf="icon === 'suffix'" matSuffix>search</mat-icon>
    <mat-icon *ngIf="icon === 'prefix'"
              matPrefix
              style="margin-right: 0.5rem; position: relative; top: 0.35rem"
    >search</mat-icon>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async"
                  [value]="option.value"
                  (onSelectionChange)="emitSelection(option, $event);"
      >
        {{ option.value }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
