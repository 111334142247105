import { Injectable } from '@angular/core';
import { convertToNumber, deepCopy } from '@saikin/util';

// TOOD: hinzugefügt, weil im ProductionMode die Models nicht über
//       "window" geladen werden können (uglify / minify zerschießt da was)

@Injectable()
export class SaikinModelStorage
{
  static instance: SaikinModelStorage;
  private models: any = {};

  constructor()
  {
    SaikinModelStorage.instance = this;
  }

  public addModel(model: any): void
  {
    this.models[model.cnstName] = model;
  }

  public getModelDefinition(modelName: string): any
  {
    const models = modelName.split('|')
      .map(m => this.models[m])
      .filter(e => e);
    return models.length > 0 ? models : undefined;
  }

  public loadModel(modelName: string): any
  {
    return new (<any> this.models)[modelName]();
  }

  public loadMatchingModel(models: Array<any>, reference: any): any
  {
    if (models.length === 1 || !reference) {
      return models[0].fromResponse(reference);
    }

    const matchingModel = { model: undefined, missing: undefined };
    for (const _model of models) {
      const ref = deepCopy(reference);
      const model = _model.fromResponse(ref);
      const missing = Object.keys(ref).length;
      if (!matchingModel.missing || matchingModel.missing > missing) {
        matchingModel.model = model;
        matchingModel.missing = missing;
      }
    }
    return matchingModel.model;
  }
}

export function SaikinModel(model: string): any           // eslint-disable-line
{
  return (instance: any, key: string,
          descriptor: PropertyDescriptor) => {            // eslint-disable-line
    instance['cnstName'] = model;
    if (key === undefined) {
      instance.prototype.cnstName = model;
    }
    else {
      instance['_' + key] = model;
    }
  };
}

export class SaikinBaseModel
{
  public id?: string;
  public etag?: string;
  public created?: string;
  public modified?: string;
  public _modelLabel = 'SaikinBaseModel';

  public __?: any = {}; // eslint-disable-line

  public static fromResponse(response: any): any
  {
    if (!response) {
      return undefined;
    }

    const modelStorage = SaikinModelStorage.instance;
    const object = modelStorage.loadModel((<any> this).cnstName);

    object.applyJson(response);
    return object;
  }

  public applyJson(json: any): void
  {
    const modelStorage = SaikinModelStorage.instance;

    const defaults = {
      id: 'id',
      etag: 'etag',
      creation_date: 'created',
      modification_date: 'modified',
    };

    for (const key of Object.keys(defaults)) {
      if (json[key]) {
        this[defaults[key]] = json[key];
        delete json[key];
      }
    }

    for (const camelKey of Object.getOwnPropertyNames(this)) {
      const snakeKey =
          camelKey.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      try {
        if (json[snakeKey] !== undefined) {
          const propClassName = this['_' + camelKey];

          if (!propClassName) {
            this[camelKey] = json[snakeKey];
          }
          else {
            const models = modelStorage.getModelDefinition(propClassName);
            if (models) {
              if (Array.isArray(this[camelKey])) {
                for (const subProperty of json[snakeKey]) {
                  this[camelKey].push(
                    modelStorage.loadMatchingModel(models, subProperty));
                }
              }
              else {
                this[camelKey] =
                  modelStorage.loadMatchingModel(models, json[snakeKey]);
              }
            }
            else {
              this[camelKey] = json[snakeKey];
            }
          }

          delete json[snakeKey];
        }
      }
      catch (e) {
        console.error(camelKey, snakeKey, json);
        throw e;
      }
    }
  }

  public static fromTemplate(template: any): any
  {
    const modelStorage = SaikinModelStorage.instance;
    const object = modelStorage.loadModel((<any> this).cnstName);

    const base = template.clone();
    for (const key of Object.getOwnPropertyNames(template)) {
      object[key] = base[key];
    }

    return object;
  }

  public clone<T>(): T|any
  {
    const copy = new (<{ new () }> this.constructor)();

    for (const key of Object.getOwnPropertyNames(this)) {
      const propClassName = this['_' + key];

      if (!propClassName) {
        copy[key] = deepCopy(this[key]);
      }
      else {
        if (Array.isArray(this[key])) {
          for (const subProperty of this[key]) {
            copy[key].push(subProperty.clone());
          }
        }
        else if (this[key] !== undefined) {
          copy[key] = this[key].clone();
        }
        else {
          copy[key] = undefined;
        }
      }
    }

    return <T> copy;
  }

  public duplicate(template: any): any
  {
    const instance = this.clone();
    instance.id = template.id;
    instance.etag = template.etag;
    instance.modified = template.modified;
    instance.created = template.created;

    return instance;
  }

  public toRequest(): any
  {
    const modelStorage = SaikinModelStorage.instance;
    const referenceObject = modelStorage.loadModel((<any> this).cnstName);

    const deleteFunctions = (requestObject) => {
      for (const key of Object.getOwnPropertyNames(requestObject)) {
        if (typeof requestObject[key] === 'function') {
          delete requestObject[key];
        }
      }

      return requestObject;
    };
    const deleteLeadingUnderscore = (requestObject) => {
      for (const key of Object.getOwnPropertyNames(requestObject)) {
        if (key.startsWith('_')) {
          delete requestObject[key];
        }
      }

      return requestObject;
    };

    const request = <any> deepCopy(this);

    const defaults = [ 'modified', 'created', 'etag' ];
    for (const key of defaults) {
      if (request[key]) {
        delete request[key];
      }
    }

    //~ preprocess attributes
    for (const key of Object.getOwnPropertyNames(this)) {
      // convert string to number (float)
      if (typeof referenceObject[key] === 'number' &&
          typeof this[key] !== 'number') {
        this[key] = convertToNumber(this[key]);
      }
    }

    for (const camelKey of Object.getOwnPropertyNames(this)) {
      const snakeKey =
          camelKey.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

      if (this[camelKey] instanceof SaikinBaseModel) {
        request[snakeKey] = this[camelKey].toRequest();
      }
      else if (Array.isArray(this[camelKey])) {
        request[snakeKey] = [];
        for (const item of this[camelKey]) {
          if (item instanceof SaikinBaseModel) {
            request[snakeKey].push(item.toRequest());
          }
          else {
            request[snakeKey].push(item);
          }
        }
      }
      else {
        request[snakeKey] = this[camelKey];
      }

      if (snakeKey !== camelKey) {
        delete request[camelKey];
      }
    }

    return deleteLeadingUnderscore(deleteFunctions(request));
  }

  public toFormData(): FormData
  {
    const formData = new FormData();

    const json = this.toRequest();
    for (const snakeKey of Object.getOwnPropertyNames(json)) {
      formData.append(snakeKey, json[snakeKey]);
    }

    return formData;
  }

  public toString(): string
  {
    return '[' + (<any> this).cnstName + '] ' + this.id;
  }
}
