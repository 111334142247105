import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData
{
  id: string;
  name: string;
  type: string;
  customId?: string;
}

@Component({
  selector: 'cnst-delete-dialog',
  templateUrl: 'delete-dialog.component.html',
})
export class DeleteDialog
{
  constructor(
    public dialogRef: MatDialogRef<DeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  )
  { }

  public cancel(): void
  {
    this.dialogRef.close();
  }
}
