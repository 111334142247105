import { Component } from '@angular/core';

@Component({
  selector: 'entry-view',
  template: `
    <article class="cnst-theme_article" style="margin-top: 0; padding-top: 1rem;">
      <router-outlet></router-outlet>
    </article>
  `,
  styles: [ 'section table { width: 100%; }' ]
})
export class SaikinEntryView {}
