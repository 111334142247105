import { environment } from '@env';

import { Inject, Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl
} from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { AuthService } from '@saikin/auth/auth.service';
import { HttpHelper } from '@saikin/http';

@Pipe({
  name: 'safeSanitize'
})
export class SafeSanitizePipe implements PipeTransform
{
  private http: HttpHelper;
  constructor(@Inject(HttpClient) http: HttpClient,
              protected authService: AuthService,
              private sanitizer: DomSanitizer
  ) {
    this.http = new HttpHelper(http, environment.serverHostAddress);
  }

  public transform(value: string, type = 'url'):
    SafeHtml|SafeStyle|SafeScript|SafeUrl|SafeResourceUrl|Observable<SafeUrl>
  {
    switch (type) {
      case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'with-auth':
        const headers = {
          Authorization: 'Bearer ' + this.authService.token
        };
        return this.http
          .get(value, headers, { responseType: 'blob' })
          .then(response => this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(response.body)
          ))
          .catch(response => {
            if (response.status === 404) {
              return this.sanitizer.bypassSecurityTrustUrl(
                  '/assets/images/image-not-found.jpg');
            }
          });
      case 'resource':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustUrl(value);
    }
  }
}
