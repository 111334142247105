import { AfterViewInit, Component, Input, OnDestroy, OnInit
       } from '@angular/core';

import Chart from 'chart.js/auto';

import { Team } from '@config/models';

@Component({ template:  `` })
export abstract class HistoryDetailsComponent implements AfterViewInit, OnInit
{
  protected abstract processData(): any;

  @Input() public instance: any = undefined;
  public chartName: string;
  public loading: boolean = false;
  public marksmen: Array<any> = [];
  public displayLegend: boolean = true;

  protected readonly colors: Array<string> = [
    'mediumblue', 'crimson', 'darkkhaki', 'lawngreen',
    'darkorange', 'gold', 'darkslategray', 'limegreen', 'olive',
    'lightseagreen', 'mediumorchid', 'rebeccapurple',
    'darkturquoise', 'lightskyblue', 'lightseagreen',
    'forestgreen',
  ];

  public ngOnInit(): void
  {
    this.chartName = 'chartContainer-' + this.instance.id;
  }

  public ngAfterViewInit(): void
  {
    const data = this.processData();
    let labels = [];
    const dataSets = [];

    for (const event of Object.keys(data)) {
      dataSets.push({
        label: event,
        data: data[event].map(d => d.y),
        backgroundColor: this.colors.shift(),
      });
      labels = data[event].map(d => d.label);
    }

    this.renderChart(this.chartName, labels, dataSets);
  }

  protected renderChart(id: string,
                        labels: Array<string>, dataSets: Array<any>): void
  {
    const ctx = (<any> document.getElementById(id)).getContext('2d');
    const cart = new Chart(ctx, {
        type: 'bar',
        data: { labels, datasets: dataSets },
        options: {
          plugins: {
            legend: {
              display: this.displayLegend,
              position: 'bottom',
            }
          }
        }
    });
  }
}
