<div class="startscreen" [ngClass]="'width-' + customWidth">
  <div class="startscreen_container">
    <div class="startscreen_body">
      <div class="startscreen_brand-wrapper">
        <img class="startscreen_brand" [src]="logoUrl" />
      </div>
      <div class="startscreen_content">
        <ng-content select="[slot=content]"></ng-content>
      </div>
    </div>
    <div class="startscreen_footer">
        <ng-content select="[slot=footer]"></ng-content>
    </div>
  </div>
</div>
