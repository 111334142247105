import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { RegistrationComponent } from './registration/registration.component';
import { HistoryComponent } from './history/history.component';

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy'
};

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'anmeldung', component: RegistrationComponent },
    { path: 'ergebnisse', component: HistoryComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRouting { }
