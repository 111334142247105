<mat-form-field theme="control"
                appearance="outline" floatLabel="always"
                style="width: 100%;">
  <mat-label>{{ label }}</mat-label>

  <input matInput
         [(ngModel)]="_value.display"
         (input)="manualInput($event.target.value);"
         (focus)="$event.target.select();
                  (mode === 'date' ? datePicker : (
                   mode === 'time' ? timePicker : dateTimePicker)).open()"
         [disabled]="disabled" />

  <input matInput style="display: none;" *ngIf="mode === 'date'"
         [matDatepicker]="datePicker"
         [(ngModel)]="_value.picker"
         (dateChange)="pickerSelect($event.value);"
         [disabled]="disabled" />

  <input matInput style="display: none;" *ngIf="mode === 'time'"
         [matTimepicker]="timePicker"
         [(ngModel)]="_value.picker"
         [format]="24"
         [disabled]="disabled" />

  <input matInput style="display: none;" *ngIf="mode === 'date-time'"
         [ngxMatDatetimePicker]="dateTimePicker"
         [(ngModel)]="_value.picker"
         (dateChange)="pickerSelect($event.value);"
         [disabled]="disabled" />

  <mat-datepicker #datePicker
                  (monthSelected)="onMonthSelected($event)"></mat-datepicker>
  <mat-timepicker #timePicker format="24h" color="primary" [disabled]="disabled"
                  [minuteInterval]="minuteInterval"
                  mode="input"
                  (closed)="pickerSelect($event?.value);"
  ></mat-timepicker>
  <ngx-mat-datetime-picker #dateTimePicker></ngx-mat-datetime-picker>

  <ng-container *ngIf="withSuffix">
    <mat-timepicker-toggle *ngIf="mode === 'time'"
                           matSuffix [for]="timepicker"
                           [disabled]="disabled"></mat-timepicker-toggle>

    <mat-datepicker-toggle *ngIf="mode !== 'time'" matSuffix
                           [for]="(mode === 'date' ? datePicker
                                                   : dateTimePicker)"
                           [disabled]="disabled"></mat-datepicker-toggle>
  </ng-container>

</mat-form-field>
