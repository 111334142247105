<div class="cnst-signature-pad">
  <div class="cnst-canvas-wrapper">
    <img [src]="imgSrc" *ngIf="isDone" class="cnst-signature">
    <canvas class="cnst-canvas" #signaturePad
            height="250"
            [style.display]="isDone ? 'none' : 'inherit'"
            (touchstart)="startDrawing($event)"
            (touchmove)="drawSignature($event)"
    ></canvas>
  </div>

  <div class="control-group" *ngIf="!isDone && showButtons">
    <button mat-stroked-button color="primary" (click)="save()">
      Bestätigen
    </button>
    <button mat-stroked-button color="warn" (click)="clear()">
      Leeren
    </button>
  </div>
</div>
