import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toString'
})
export class ToStringPipe implements PipeTransform
{
  public transform(value: any, param?: string): string
  {
    if (!value) {
      return '';
    }

    if (typeof value.toString === 'function') {
      return value.toString();
    }
    else if (param) {
      return value[param];
    }
  }
}
