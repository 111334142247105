export const permissionsList = {
  USER: {
    _ALL: [ 'user-edit', 'user-edit-permissions' ],
    EDIT: 'user-edit',
    EDIT_PERMISSIONS: 'user-edit-permissions',
  },
  MEMBER: {
    _ALL: [ 'member-display', 'member-edit', 'member-statistics',
            'member-honors', 'zmi-update' ],
    DISPLAY: 'member-display',
    EDIT: 'member-edit',
    STATISTICS: 'member-statistics',
    HONORS: 'member-honors',
    UPDATE: 'zmi-update',
  },
  NEWS: {
    _ALL: [ 'news-edit' ],
    EDIT: 'news-edit',
  },
  APPOINTMENT: {
    _ALL: [ 'appointment-edit', 'appointment-sync' ],
    EDIT: 'appointment-edit',
    SYNC: 'appointment-sync',
  },
  WHITSUN: {
    _ALL: [ 'whitsun-teams-edit', 'whitsun-marksmen-edit', 'whitsun-scoring',
            'whitsun-luckypersons-edit' ],
    EDIT_TEAMS: 'whitsun-teams-edit',
    EDIT_MARKSMEN: 'whitsun-marksmen-edit',
    EDIT_LUCK: 'whitsun-luckypersons-edit',
  },
  KINGCUP: {
    _ALL: [ 'kingscup-marksmen-edit', 'kingscup-results-display' ],
    EDIT_MARKSMEN: 'kingscup-marksmen-edit',
    DISPLAY_RESULTS: 'kingscup-results-display',
  },
  DOCUMENTS: {
    _ALL: [ 'edit-document', 'send-document' ],
    EDIT: 'edit-document',
    SEND: 'send-document',
  },
};

export const userPermissions = {
  admin: []
    .concat(permissionsList.USER._ALL)
    .concat(permissionsList.MEMBER._ALL)
    .concat(permissionsList.NEWS._ALL)
    .concat(permissionsList.APPOINTMENT._ALL)
    .concat(permissionsList.WHITSUN._ALL)
    .concat(permissionsList.KINGCUP._ALL)
    .concat(permissionsList.DOCUMENTS._ALL),
  directorate: []
    .concat([
      permissionsList.MEMBER.DISPLAY,
      permissionsList.MEMBER.EDIT,
      permissionsList.MEMBER.HONORS,
      permissionsList.MEMBER.STATISTICS,
    ])
    .concat(permissionsList.NEWS._ALL)
    .concat(permissionsList.APPOINTMENT._ALL)
    .concat(permissionsList.WHITSUN._ALL)
    .concat(permissionsList.KINGCUP._ALL)
    .concat(permissionsList.DOCUMENTS._ALL)
};
