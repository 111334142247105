import { environment } from '@env';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from '@saikin/auth/auth.service';
import { HttpHelper, RequestResult } from '@saikin/http';
import { getMimeType } from '@saikin/util';

interface ResponseType
{
  responseType?: string;
}

@Injectable()
export abstract class SaikinService
{
  protected http: HttpHelper;
  protected baseUrl: string;
  protected baseUrlSuffix = '';

  constructor(
      @Inject(HttpClient) http: HttpClient,
      protected authService: AuthService,
      protected snackBar: MatSnackBar,
      protected sanitizer: DomSanitizer
  )
  {
    this.baseUrl = environment.serverHostAddress;
    this.http = new HttpHelper(http, this.baseUrl + this.baseUrlSuffix);
    this.init();
  }

  protected async init(): Promise<void>
  {
    // nothing here
  }

  protected async createEntity<T>(endpoint: string, entity: T): Promise<any>
  {
    const payload = (<any> entity).toRequest();
    return this.http.post(endpoint, payload, this.getHeaders());
  }

  protected async updateEntity<T>(endpoint: string, entity: T): Promise<any>
  {
    const payload = (<any> entity).toRequest();
    const headers = {
      ...this.getHeaders(),
      'If-Match': (<any> entity).etag
    };

    return this.http.patch(endpoint, payload, headers);
  }

  protected async deleteEntity<T>(endpoint: string, entity: T):
    Promise<void>
  {
    const headers = {
      ...this.getHeaders(),
      'If-Match': (<any> entity).etag
    };

    await this.http.delete(endpoint, headers);
  }

  protected getHeaders(): { [header: string]: string }
  {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.authService.token,
      //~ 'Cache-Control': 'no-cache',
    };
  }

  protected applyEtag(object: any, response: RequestResult):
    void
  {
    object.etag = this.getEtag(response);
  }

  protected getEtag(response: RequestResult): string
  {
    return response.headers.get('etag').replace(/"/g, '');
  }

  protected getResponseType(accept: string): ResponseType
  {
    switch (accept) {
      case 'application/json':
      case 'plain/text':
        return {};
      default:
        return { responseType: 'arraybuffer' };
    }
  }

  protected async _getAsTypeFile(url: string, type: string,
                                 filterIds: string[] = []): Promise<string>
  {
    const headers = {
      ...this.getHeaders(),
      Accept: getMimeType(type)
    };

    return this._getWithFilteredIds(url, headers, filterIds);
  }

  protected async _getWithFilteredIds(url, headers, filterIds: string[] = []):
    Promise<string>
  {
    if (filterIds.length > 0) {
      const payload = { filter_ids: filterIds };
      const response = await this.http.post(
          url, payload, headers, this.getResponseType(headers.Accept));
      return response.body;
    }
    else {
      const response = await this.http.get(
          url, headers, this.getResponseType(headers.Accept));
      return response.body;
    }
  }

  protected async getSafeSanitizeUrlWithAuth(url: string): Promise<SafeUrl>
  {
    url = this.baseUrl + url;
    const headers = {
      Authorization: 'Bearer ' + this.authService.token,
    };

    try {
      const resp = await this.http.get(url, headers, { responseType: 'blob' });
      const trusted =  this.sanitizer
          .bypassSecurityTrustUrl(URL.createObjectURL(resp.body));

      return (<any> trusted).changingThisBreaksApplicationSecurity ?? trusted;
    }
    catch(response) {
      if (response.status === 404) {
        return this.sanitizer.bypassSecurityTrustUrl(
            '/assets/images/image-not-found.jpg');
      }
    }
  }
}
