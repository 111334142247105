import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, ViewChild,
         Inject, Output
} from '@angular/core';

import { AuthService } from '@saikin/auth/auth.service';
import { HttpHelper } from '@saikin/http';

import { FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'search-field',
  templateUrl: './search-field.component.html',
  styleUrls: [ './search-field.component.scss' ]
})
export class SearchFieldComponent
{
  protected http: HttpHelper;

  @Input() appearance = 'fill';
  @Input() icon = 'suffix';
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchField') public searchField: ElementRef;

  public dropdownControl: FormControl = new FormControl();
  public filteredOptions: Observable<string[]>;

  private entries: Array<any> = [];
  private lastSelection: any = undefined;

  constructor(
      @Inject(HttpClient) http: HttpClient,
      protected authService: AuthService
  )
  {
    this.http = new HttpHelper(http, environment.serverHostAddress);
  }

  public updateList(): void
  {
    const input = this.searchField.nativeElement.value;
    if (input.length > 2) {
      this.getListFromServer(input).then(entries => {
        this.entries = entries;
        this.filteredOptions =
          this.dropdownControl.valueChanges.pipe(
            startWith(''),
            filter(x => typeof x === 'string'),
            map(item => item.value),
            map(name => name
              ? this.entries.filter(o => o.value.toLowerCase()
                .indexOf(name.toLowerCase()) > -1)
              : this.entries.slice()
            )
          );
      });
    }
  }

  public clear(): void
  {
    this.dropdownControl.setValue(undefined);
    this.searchField.nativeElement.value = '';
  }

  public emitSelection(selection: any, event: any = undefined): void
  {
    if (selection !== undefined) {
      if (!event || (event && event.isUserInput)) {
        this.selected.emit(selection);
        this.clear();
      }
    }
  }

  public displayList(): void
  {
    const value = !this.dropdownControl.value ?
      '' : this.dropdownControl.value;
    this.dropdownControl.setValue(value, { emitEvent: true });
  }

  private async getListFromServer(input: string): Promise<Array<any>>
  {
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.authService.token,
    };
    const response = await this.http
      .get('/search?q=' + encodeURIComponent(input), headers);
    return response.body;
  }
}
