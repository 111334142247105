import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef,
         ViewContainerRef
} from '@angular/core';

import { AuthService } from './auth.service';

@Directive({
  selector: '[canDo]'
})
export class AuthCanDoDirective implements OnInit
{
  private permission: string = undefined;

  constructor(
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef,
      private authService: AuthService,
      protected cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void
  {
    this.updateView();
  }

  @Input() set canDo(permission: string)
  {
    this.permission = permission;
  }

  private async updateView(): Promise<void>
  {
    if (await this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
    this.cdr.detectChanges();
  }

  private async checkPermission(): Promise<boolean>
  {
    let accessGranted = false;

    if (this.authService.canDo(this.permission)) {
      accessGranted = true;
    }

    return accessGranted;
  }
}
