<h1 mat-dialog-title>{{ data.type }} löschen</h1>

<div mat-dialog-content>
  <p>Wollen Sie wirklich folgendes Element löschen?</p>
  <strong>
    <span *ngIf="data.customId">[ {{ data.customId }} ]:</span>
    {{ data.name }}
  </strong>
  <hr />
  <span style="font-style: italic">
    Das gelöschte Element wird nicht vollständig aus der Datenbank
    entfernt, sondern verbleibt bis zur Löschung des Accounts im Archiv
  </span>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="cancel()" cdkFocusInitial
          [attr.data-action]="'cancel-delete'">Abbrechen</button>
  <button mat-button [mat-dialog-close]="data.id"
          [attr.data-action]="'confirm-delete'">
    Ja, löschen
  </button>
</div>
