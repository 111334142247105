import { DatePipe } from '@angular/common';
import { Component, Input, Output, EventEmitter
       } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { AbstractEditComponent, EDIT_OPERATION
} from '@saikin/components/_base/edit';
import { accessBlobUrl, deepCopy } from '@saikin/util';

import { eventDates } from '@config/event';
import { Team, TeamResult } from '@config/models';
import { WhitsunService } from '../whitsun.service';

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
})
export class RegistrationComponent extends AbstractEditComponent<Team>
{
  public instance: Team = new Team();
  public startDate: any;
  public _startDate: any;
  public confirmationEmail: string = '';
  public eventDates: Array<string> = eventDates;
  public activeEvent: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private whitsunService: WhitsunService,
    private datePipe: DatePipe)
  {
    super();

    const now = new Date();
    for (let date of eventDates) {
      this._startDate = new Date(date);
      if (this._startDate >= now) {
        break;
      }
    }

    if (now > this._startDate) {
      this.activeEvent = false;
      this.startDate = this._startDate.getYear();
    }

    this.startDate = this.formatDate(this._startDate);
  }

  protected async loadInstance(): Promise<void>
  {
    while (this.instance.results.length < 4) {
      this.instance.results.push(new TeamResult());
    }
  }

  protected async resetInstance(): Promise<void>
  {
    this.instance = new Team();
  }

  protected async createInstance(): Promise<boolean>
  {
    return this.executeInstanceOperation(async () => {
      await this.whitsunService.createTeam(this.instance, {
          start_date: this.startDate,
          confirmation_email: this.confirmationEmail,
      });

    }, EDIT_OPERATION.CREATE);
  }

  public formatDate(date: any): string
  {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm');
  }
}
