<mat-form-field *ngIf="!(preferNative || isSmartphone)"
                theme="control" appearance="outline" floatLabel="always"
                style="width: 100%">
  <mat-label *ngIf="label && label.length > 0">{{ label }}</mat-label>

  <mat-select [(ngModel)]="value" (ngModelChange)="emit()"
              [multiple]="multiple">
    <mat-option *ngFor="let option of options"
                [disabled]="option.disabled"
                [value]="option.value">{{ option.text }}</mat-option>

    <ng-container *ngIf="allowNewOption">
      <mat-option *ngIf="unregisteredValue" [value]="unregisteredValue"
      >{{ unregisteredValue }}</mat-option>

      <mat-option value="">{{ allowNewText }}</mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>

<ng-container *ngIf="preferNative || isSmartphone">
  <mat-form-field *ngIf="!multiple"
                theme="control" appearance="outline" floatLabel="always"
                style="width: 100%">
    <mat-label *ngIf="label && label.length > 0">{{ label }}</mat-label>

    <select matNativeControl [(ngModel)]="value" (ngModelChange)="emit()">
      <option *ngFor="let option of options"
              [disabled]="option.disabled"
              [value]="option.value">
        {{ option.text }}
      </option>

      <ng-container *ngIf="allowNewOption">
        <option *ngIf="unregisteredValue"
                [value]="unregisteredValue"
        >{{ unregisteredValue }}</option>

        <option value="">{{ allowNewText }}</option>
      </ng-container>
    </select>
  </mat-form-field>

  <mat-form-field *ngIf="multiple"
                theme="controls" appearance="outline" floatLabel="always"
                style="width: 100%">
    <mat-label *ngIf="label && label.length > 0">{{ label }}</mat-label>
    <input matInput style="display: none">

    <mat-checkbox *ngFor="let option of options"
        class="linebreak" [color]="color"
        [value]="option.value"
        [checked]="value.indexOf(option.value) > -1"
        (change)="setOptionValueCheckbox(option.value, $event.checked)"
        [disabled]="option.disabled"
    >{{ option.text }}</mat-checkbox>

    <ng-container *ngIf="allowNewOption">
      <mat-checkbox *ngIf="unregisteredValue" [color]="color"
          [value]="unregisteredValue"
          [checked]="value.indexOf(unregisteredValue) > -1"
          (change)="setOptionValueCheckbox(unregisteredValue, $event.checked)"
      >{{ unregisteredValue }}</mat-checkbox>

      <mat-checkbox [color]="color"
          [value]=""
          [checked]="value.indexOf('') > -1"
          (change)="setOptionValueCheckbox('', $event.checked)"
      >{{ allowNewText }}</mat-checkbox>

      <mat-checkbox value="">{{ allowNewText }}</mat-checkbox>
    </ng-container>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="allowNewOption && value === ''">

  <mat-form-field *ngIf="allowNew === 'line'" theme="control"
                  appearance="outline" floatLabel="always"
                  style="width: 100%">
    <mat-label>Auswahl</mat-label>
    <input matInput name="answer_other"
           #newOption
           (focus)="$event.target.select()" />

    <button matSuffix mat-raised-button color="primary"
            style="top: -0.35rem; margin-left: 1.5rem;"
            (click)="emit(newOption.value);">OK</button>
  </mat-form-field>

  <div class="cnst-row" *ngIf="allowNew === 'area'">
    <div class="cnst-col adopt-width">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Text</mat-label>
        <textarea matInput
                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="8" cdkAutosizeMaxRows="8"
                  [(ngModel)]="textareaValue"
                  (change)="setTextAreaValue()"
                  name="test_load_notice"
                  placeholder=""
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</ng-container>
