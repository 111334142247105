import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cnstFilter',
    pure: false
})
export class CnstFilterPipe implements PipeTransform
{
  transform(items: any[], callback: any, filter: string): any
  {
    if (!items || !callback) {
      return items;
    }

    return items.filter(i => { return callback(i, filter); });
  }
}
