import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component,
         AfterViewInit, inject, OnDestroy
} from '@angular/core';

import { LoadingIndicator } from '@saikin/util';

@Component({
  selector: 'abstract-saikin-base-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ''
})
export abstract class BaseComponent implements AfterViewInit, OnDestroy
{
  public loading = false;
  public loadingIndicator: LoadingIndicator = new LoadingIndicator();
  protected subscriptions: Array<Subscription> = [];

  protected cdr = inject(ChangeDetectorRef);
  protected snackBar = inject(MatSnackBar);
  protected dialog = inject(MatDialog);

  public ngOnDestroy(): void
  {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public ngAfterViewInit(): void
  {
    // nothing here
  }
}
