<div class="wrapper">
  <div class="chart-container-wrapper">
    <div class="chart-container">
      <canvas id="{{chartName}}"></canvas>
    </div>
  </div>

  <div class="marksmen">
    <div class="cnst-row">
      <div class="cnst-col adopt-width" *ngFor="let event of marksmen">
        <div>
          <strong>{{ event.year }}</strong><br />
          <ng-container *ngFor="let marksman of event.marksmen">
            {{ marksman.surname }} {{ marksman.prename }}
            ({{ marksman.results[0] }})<br />
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
