export enum TooltipPosition {
  ABOVE = 'above',
  BELOW = 'below',
  LEFT = 'left',
  RIGHT = 'right',
  DYNAMIC = 'dynamic',
  DEFAULT = 'above'
}

export enum TooltipTheme {
  DARK = 'dark',
  LIGHT = 'light',
  DEFAULT = 'dark'
}

import { Component } from '@angular/core';

@Component({
  selector: 'tooltip',
  template: `
    <div class="tooltip"
         [ngClass]="['tooltip--'+position, 'tooltip--'+theme]"
         [class.tooltip--visible]="visible"
         [style.left]="left + 'rem'"
         [style.top]="top + 'rem'"
         style="z-index: 1000;">
      {{tooltip}}
    </div>
  `,
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent
{
  position: TooltipPosition = TooltipPosition.DEFAULT;
  theme: TooltipTheme = TooltipTheme.DEFAULT;
  tooltip = '';
  left = 0;
  top = 0;
  visible = false;
}
