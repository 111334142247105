<form *ngIf="!disabled">
  <mat-form-field theme="control"
                  appearance="outline" floatLabel="always" style="width:100%">
    <mat-label>{{ placeholder }}</mat-label>
    <input matInput type="text" #searchField [required]="required"
           [placeholder]="placeholder"
           [formControl]="dropdownControl"
           [matAutocomplete]="auto"
           (focusout)="focusOut()"
           (click)="displayList()"
           aria-label="Assignee"
           name="displayField"
    />
    <mat-icon *ngIf="!deleteSuffix" matSuffix>search</mat-icon>
    <mat-icon *ngIf="deleteSuffix" matSuffix color="warn"
              (click)="deleted.emit()"
    >delete</mat-icon>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async"
                  [value]="option.display"
                  [attr.selected]="option.display === toString(value)"
                  (onSelectionChange)="emitSelection(option.value, $event);"
      >
        {{ option.display }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<form *ngIf="disabled">
  <mat-form-field theme="control" appearance="outline" floatLabel="always">
    <mat-label>{{ placeholder }}</mat-label>
    <input matInput type="text" disabled [required]="required"
           [value]="toString(value)"
    />
  </mat-form-field>
</form>
