import { AfterViewInit, ContentChildren, Component, EventEmitter, Input, OnInit,
         Output, QueryList
} from '@angular/core';
import { MatOption } from '@angular/material/core';


@Component({
  selector: 'cnst-mat-select',
  templateUrl: './cnst-select.component.html',
})
export class CnstMatSelectComponent implements OnInit, AfterViewInit
{
  @Input() label = '';
  @Input() options: Array<any> = [];
  @Input() multiple = false;
  @Input() preferNative = false;
  @Input() color = 'primary';

  @Input() allowNew: string = undefined;
  @Input() allowNewText = 'neu ...';
  public allowNewOption = false;
  public textareaValue: string = '';

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  public isSmartphone = false;

  @ContentChildren(MatOption, { descendants: true })
  public _options: QueryList<MatOption>;
  private registeredValues = [];
  public unregisteredValue = undefined;

  public constructor()
  {
    window.onresize = () => this.determineSmarpthone();
  }

  public ngOnInit(): void
  {
    this.determineSmarpthone();
    this.allowNewOption = this.allowNew !== undefined;
  }

  public ngAfterViewInit(): void
  {
    this._options.forEach((element) => {
      this.registeredValues.push(element.value);

      this.options.push({
          value: element.value,
          text: element.viewValue,
          disabled: element.disabled
      });
    });

    if (this.registeredValues.indexOf(this.value) === -1) {
      this.unregisteredValue = this.value;
      if (this.allowNew === 'area') {
        this.textareaValue = this.unregisteredValue;
      }
    }
  }

  public emit(value?: any): void
  {
    const isUnregisteredValue = value && (
      this.multiple ? (this.value.indexOf(value) === -1) :
                      (this.value !== value));

    if (isUnregisteredValue) {
      if (this.registeredValues.indexOf(value) === -1) {
        this.unregisteredValue = value;
      }
      if (this.multiple) {
        this.value.push(value);
      }
      else {
        this.value = value;
      }
    }

    this.valueChange.emit(this.value);
  }

  public setOptionValueCheckbox(value: any, checked: boolean): void
  {
    const idx = this.value.indexOf(value);
    if (checked) {
      if (idx === -1) {
        this.value.push(value);
      }
    }
    else {
      if (idx > -1) {
        this.value.splice(idx, 1);
      }
    }
    this.emit();
  }

  public setTextAreaValue(): void
  {
    console.log("change", this.textareaValue);
    this.emit(this.textareaValue);
  }

  private determineSmarpthone()
  {
    const browsers = [ 'Android', 'webOS', 'iPhone', 'iPad', 'iPod',
                       'BlackBerry', 'IEMobile', 'Opera Mini', 'Mobile' ];
    const regex = new RegExp(browsers.join('|'), 'i');
    this.isSmartphone = regex.test(navigator.userAgent);

    if (!this.isSmartphone) {
      this.isSmartphone = window.innerWidth < 400;
    }
  }
}
