import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { LoadingIndicator } from '@saikin/util';

@Component({
  selector: 'cnst-button-section',
  templateUrl: './button-section.component.html',
})
export class CnstButtonSectionComponent implements OnChanges
{
  @Input() inline = false;

  @Input() prefix: string;
  @Input() waitForIt: LoadingIndicator = new LoadingIndicator();
  @Input() exists: boolean;

  @Input() btnAdd: () => void = undefined;
  @Input() btnEdit: () => void = undefined;
  @Input() btnDelete: () => void = undefined;
  @Input() btnAddReturn: () => void = undefined;
  @Input() btnAddRepeat: () => void = undefined;
  @Input() btnReset: () => void = undefined;

  @Input() btnEditText: string = undefined;

  public constructor(private cdr: ChangeDetectorRef)
  {
    this.cdr.detach();
  }

  public async ngOnChanges(): Promise<void>
  {
    this.cdr.detectChanges();
  }

  public execute(fn: () => void): void
  {
    fn();
  }
}
