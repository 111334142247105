import { Component } from '@angular/core';

import { eventDates, ceremonyDate } from '@config/event';

@Component({
  templateUrl: './home.component.html',
})
export class HomeComponent
{
  public eventDates: Array<string> = eventDates;
  public ceremonyDate: string = ceremonyDate;
}
