import { SaikinBaseModel, SaikinModel } from '@saikin/saikin.model';

@SaikinModel('User')
export class User extends SaikinBaseModel
{
  _modelLabel: string = 'Benutzer';

  username: string = '';
  firstName: string = '';
  lastName: string = '';
  password?: string = '';
  role?: string = 'default'; // TODO: Konstante / Enum
  status?: string = 'active'; // TODO: Konstante / Enum

  position: string = '';

  permissions: Array<string> = [];

  public toString(): string
  {
    return this.firstName + ' ' + this.lastName + ' (' + this.username + ')';
  }
}

@SaikinModel('WhitsunEvent')
export class WhitsunEvent extends SaikinBaseModel
{
  _modelLabel: string = 'Event';

  label: string = '';
  startDate: string = '';
  endDate: string = '';
  revealDate: string = '';
}

@SaikinModel('Team')
export class Team extends SaikinBaseModel
{
  _modelLabel: string = 'Team';

  eventId?: string = '';
  name: string = '';
  subteam: string = '1';
  type: string = 'mixed';
  payed: boolean = false;
  payment: string = 'cash';

  @SaikinModel('TeamResult')
  results: Array<TeamResult> = [];

  public static fromResponse(response: any): Team
  {
    const results = response.results.map((r) => TeamResult.fromResponse(r));
    while (results.length < 4) {
      results.push(new TeamResult());
    }
    const model = super.fromResponse(response);
    model.results = results;
    return model;
  }
}

@SaikinModel('TeamResult')
export class TeamResult extends SaikinBaseModel
{
  eventId?: string = '';

  @SaikinModel('Marksman')
  marksman: Marksman = new Marksman();

  result: number = 0;
  isPistol: boolean = false;
  isWritten: boolean = false;
}

@SaikinModel('Marksman')
export class Marksman extends SaikinBaseModel
{
  _modelLabel: string = 'Schütze';

  prename: string = '';
  surname: string = '';

  results: Array<number> = [];
}

@SaikinModel('StatisticMarksman')
export class StatisticMarksman extends SaikinBaseModel
{
  eventId?: string = '';

  _modelLabel: string = 'Schütze';

  prename: string = '';
  surname: string = '';

  @SaikinModel('StatisticResult')
  results: Array<StatisticResult> = [];
}

@SaikinModel('StatisticResult')
export class StatisticResult extends SaikinBaseModel
{
  result: number = 0;

  @SaikinModel('Team')
  team: Team = new Team();
}

