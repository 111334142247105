import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse
} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Subject } from 'rxjs';

interface ErrorField
{
  property: string;
  wrapper: string;
  message: string;
}

export interface RequestError
{
  name: string;
  status: number;
  statusText: string;
  detail?: string;
  fields?: Array<ErrorField>;
}

export interface RequestResult {
  body: any;
  headers: any;
  status: number;
}

interface RequestOptions {
  headers?: HttpHeaders;
  responseType?: 'text' | 'json';
}

export interface ErrorSubject
{
  name: string;
  url: string;
  message: string;
  details: string;
}

interface HeadersDefinition { [name: string]: string }

export class HttpHelper
{
  private errorSubject: Subject<ErrorSubject> = new Subject<ErrorSubject>();

  constructor(private client: HttpClient, private baseUrl: string)
  { }

  public async head(path: string, headers?: HeadersDefinition):
    Promise<RequestResult>
  {
    return this.request('HEAD', path, [200, 204, 404], this.headers(headers));
  }

  public async get(path: string, headers?: HeadersDefinition, options?: any):
    Promise<RequestResult>
  {
    return this.request('GET', path, [200, 204, 205],
                        this.headers(headers, options));
  }

  public async put(path: string, body: any,
    headers?: HeadersDefinition, options?: any):
    Promise<RequestResult>
  {
    return this.request(
      'PUT', path, [200, 201, 204], this.headers(headers, options), body);
  }

  public async post(path: string, body: any,
    headers?: HeadersDefinition, options?: any):
    Promise<RequestResult>
  {
    return this.request(
      'POST', path, [200, 201, 204, 206], this.headers(headers, options), body);
  }

  public async patch(path: string, body: any,
    headers?: HeadersDefinition, options?: any):
    Promise<RequestResult>
  {
    return this.request(
      'PATCH', path, [200, 201, 204], this.headers(headers, options), body);
  }

  public async delete(path: string, headers?: HeadersDefinition):
    Promise<RequestResult>
  {
    return this.request(
      'DELETE', path, [200, 201, 204], this.headers(headers));
  }

  public async request(method: string,
    path: string,
    expectedStatus?: Array<number>,
    options?: RequestOptions,
    body?: any): Promise<RequestResult>
  {
    const req = this.buildRequest(method, path, options, body);

    const isExpectedStatus = (s: number) =>
      (! expectedStatus) || expectedStatus.indexOf(s) !== -1;

    const isSyntaxError = (error): boolean => {

      const _err = error.error
        ? (error.error.error ? error.error : error)
        : error;
      if (_err.error && _err.error.stack.startsWith('SyntaxError')) {
        this.dispatchErrorEvent('SyntaxError', path, _err);
        return true;
      }

      return false;
    };

    try {
      const resp = await this.client.request(req).toPromise();

      if (resp instanceof HttpResponse && isExpectedStatus(resp.status)) {
        return {
          body: (<any> resp).body,
          headers: (<any> resp).headers,
          status: resp.status
        };
      } else {
        throw this.toRequestError(resp);
      }
    } catch (error) {
      if (error instanceof HttpErrorResponse &&
          isExpectedStatus(error.status) && !isSyntaxError(error)
      ) {
        return {
          body: undefined,
          headers: undefined,
          status: error.status
        };
      } else {
        throw this.toRequestError(error);
      }
    }
  }

  private headers(
    entries: HeadersDefinition | undefined,
    options?: any | undefined): RequestOptions
  {
    let allOptions = { headers: new HttpHeaders(entries || {}) };
    if (options) {
      allOptions = { ...allOptions, ...options };
    }
    return allOptions;
  }

  private buildRequest(
    method: string, path: string, options?: RequestOptions, body?: string):
      HttpRequest<any>
  {
    const url = path.startsWith('http') ? path : this.baseUrl + path;

    if (options === undefined) {
      options = {headers: new HttpHeaders()};
    }

    if (body === undefined) {
      return new HttpRequest(method, url, options);
    }

    else {
      return new HttpRequest(method, url, body, options);
    }
  }

  private toRequestError(response: any): RequestError
  {
    let error =
      response.error.error ?? response.error ?? response ?? undefined;
    if (typeof error === 'string') {
      error = JSON.parse(error);
    }

    const detail = error
      ? error.detail ?? error.message ?? 'undefined Reason'
      : 'undefined Reason';

    return <RequestError> {
      name: response.name,
      status: response.status,
      statusText: response.statusText,
      detail,
      fields: response.error ? response.error.fields : []
    };
  }

  private dispatchErrorEvent(name: string, path: string, err: any): void
  {
    const event = document.createEvent('Event');
    event.initEvent('httperror', true, true);
    (<any> event).error = <ErrorSubject> {
      url: path,
      name,
      message: err.error.message,
      details: err.text,
    };
    document.dispatchEvent(event);
  }
}

@Injectable()
export class SaikinErrorHandler implements ErrorHandler
{
  constructor(
    protected snackBar: MatSnackBar,
    private zone: NgZone
  ) {}

  public handleError(error: any)
  {
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection; // get the error object
    }
    this.zone.run(() => {
      const errorMessage = error?.message ?? error?.detail ?? error;
      if (errorMessage) {
        this.snackBar.open(
          'Uncaught Exception: ' + errorMessage, 'OK', {
          duration: 0, panelClass: ['snackbar-error'] });
      }
    });

    if (error) {
      console.error('Error from global error handler', error);
    }
  }
}
