import { DatePipe } from '@angular/common';
import { HashLocationStrategy, LocationStrategy, registerLocaleData
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { SaikinModule } from '@saikin/saikin.module';
import { DeleteDialog } from '@saikin/dialogs/delete-dialog.component';

// Date, Time, Local
import { NgxMatNativeDateModule
} from '@angular-material-components/datetime-picker';
import { LOCALE_ID } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CnstDateAdapter, CNST_DATE_FORMATS } from '@saikin/date-adapter';

// Navigation
import { NavigationComponent } from '@saikin/navigation/navigation.component';

import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { WhitsunService } from './whitsun.service';

import { HomeComponent } from './home/home.component';
import { RegistrationComponent } from './registration/registration.component';
import { HistoryComponent } from './history/history.component';
import { TeamDetailsComponent } from './history/team-details.component';
import { MarksmanDetailsComponent } from './history/marksman-details.component';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    NavigationComponent,
    AppComponent,
    DeleteDialog,

    HomeComponent,
    RegistrationComponent,
    HistoryComponent,
    TeamDetailsComponent, MarksmanDetailsComponent
  ],
  imports: [
    SaikinModule,
    AppRouting,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMatNativeDateModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'de-DE' } ,
    { provide: DateAdapter, useClass: CnstDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CNST_DATE_FORMATS },
    WhitsunService,
    DatePipe
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule { }
