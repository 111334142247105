import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@saikin/auth/auth.service';
import { SaikinModelStorage } from '@saikin/saikin.model';
import { WhitsunService } from './whitsun.service';

import * as Models from '@config/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{
  @ViewChild('sidenav') public sidenav: ElementRef;
  public logoUrl: string = undefined;

  public screenWidth: number;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private authService: AuthService,
    private whitsunService: WhitsunService
  ) {
    window.onresize = () => { this.screenWidth = window.innerWidth; };

    // TODO: so schön ist das ja nicht ...
    const modelStorage = new SaikinModelStorage();
    for (const modelName of Object.keys(Models)) {
      modelStorage.addModel(Models[modelName]);
    }

    this.whitsunService.getEvents();
  }

  public async ngOnInit(): Promise<void>
  {
    this.screenWidth = window.innerWidth;
  }
}
