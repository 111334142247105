<section class="cnst-theme_section">
  <div class="cnst-theme_section-header">
    <div class="cnst-row justify-space-between align-center">
      <div class="cnst-col auto">
        <h2 class="mat-title cnst-theme_section-title">
          Mannschaftsanmeldung
        </h2>
      </div>
    </div>
  </div>

  <ng-container *ngIf="activeEvent; else eventIsOver">
    <div class="cnst-theme_section-body">
      <h3 class="mat-subheading-2 cnst-theme_section-body-title">
        Mannschaft
      </h3>
      <div class="cnst-row">
        <div class="cnst-col adopt-width">
          <mat-form-field theme="control"
                          appearance="outline" floatLabel="always">
            <mat-label>Manschaffts-Name</mat-label>
            <input matInput placeholder="Name" name="name"
                   [(ngModel)]="instance.name" />
          </mat-form-field>
        </div>
        <div class="cnst-col auto">
          <mat-form-field theme="control"
                        appearance="outline" floatLabel="always">
            <mat-label>Mannschaft</mat-label>
            <mat-select [(value)]="instance.subteam" name="user_role">
              <mat-option *ngFor="let s of [1,2,3,4,5,6,7,8]"
                          [value]="''+s">{{ s }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="cnst-col auto">
          <mat-form-field theme="control"
                          appearance="outline" floatLabel="always">
            <mat-label>gewünschte Zahlungsart</mat-label>
            <mat-select [(value)]="instance.payment">
              <mat-option [value]="'cash'">Bar</mat-option>
              <mat-option [value]="'transfer'">Überweisung</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="cnst-theme_section-body">
      <h3 class="mat-subheading-2 cnst-theme_section-body-title">
        Schützen
      </h3>

      <div class="cnst-row" style="margin: 1rem -12px;"
           *ngFor="let result of instance.results; let i = index">
        <div class="cnst-col adopt-width">
          <mat-form-field theme="control"
                          appearance="outline" floatLabel="always">
            <mat-label>Vorname</mat-label>
            <input matInput placeholder="Name" name="name"
                   [(ngModel)]="instance.results[i].marksman.prename" />
          </mat-form-field>
        </div>
        <div class="cnst-col adopt-width">
          <mat-form-field theme="control"
                          appearance="outline" floatLabel="always">
            <mat-label>Nachname</mat-label>
            <input matInput placeholder="Name" name="name"
                   [(ngModel)]="instance.results[i].marksman.surname" />
          </mat-form-field>
        </div>

        <div class="cnst-col adopt-width">
          <div class="cnst-col auto">
            <div class="cnst-row">
              <div class="cnst-col adopt-width">
                <mat-checkbox
                  [ngModel]="result.isPistol"
                  (change)="result.isPistol = $event.checked"
                >Pistolenschütze</mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cnst-theme_section-body">
      <h3 class="mat-subheading-2 cnst-theme_section-body-title">
        Organisation
      </h3>

      <div class="cnst-row">
        <div class="cnst-col adopt-width">
          <mat-form-field theme="control"
                          appearance="outline" floatLabel="always">
            <mat-label>Bestätigungs-E-Mail</mat-label>
            <input matInput placeholder="Bestätigungs-E-Mail" name="email"
                   [(ngModel)]="confirmationEmail" />
          </mat-form-field>
        </div>

        <div class="cnst-col adopt-width">
          <mat-form-field theme="control"
                          appearance="outline" floatLabel="always">
            <mat-label>Wunsch-Zeitraum</mat-label>
            <input matInput name="pfingsten-datetime"
                   [ngModel]="_startDate"
                   placeholder="Wunsch-Zeitraum"
                   [ngxMatDatetimePicker]="startDateEx"
                   (click)="startDateEx.open()"
                   (dateChange)="startDate = formatDate($event.value)">
            <mat-datepicker-toggle matSuffix [for]="startDateEx">
            </mat-datepicker-toggle>
            <ngx-mat-datetime-picker #startDateEx
                                     [startAt]="'18:30:00'"
                                     [showSeconds]="false">
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
        <div class="cnst-col adopt-width"></div>
      </div>
      <div class="cnst-row">
        <div class="cnst-col adopt-width">
          Du willst keine Bestätigungs-Email? Dann lass das Feld einfach leer ;-)
          <br />
          Für den Wunschzeitraum stehen dir folgende Tage zur Verfügung:
          <span *ngFor="let date of eventDates">
            |  {{ date | date: 'EEEE, dd.MM.' }}
          </span>
        </div>
      </div>
    </div>

    <cnst-button-section
      [waitForIt]="loading"
      [prefix]="'Mannschaft'" [exists]="instance.id"
      [btnAdd]="createInstanceFN"
    ></cnst-button-section>
  </ng-container>

  <ng-template #eventIsOver>
    <div class="cnst-theme_section-body">
      <h3 class="mat-subheading-2 cnst-theme_section-body-title">
        Pfingstschießen beendet
      </h3>

      <div class="cnst-row">
        <div class="cnst-col adopt-width">
          Das Pfingstschießen für das Jahr {{ startDate | date: 'YYYY' }}
          ist beendet.<br />
          Die Ergebnisse sind <a [routerLink]="'/ergebnisse'">hier</a> zu finden.
        </div>
      </div>
      <div class="cnst-row">
        <div class="cnst-col adopt-width">
          Wir bedanken uns für die Teilnahme und hoffen, dass wir euch beim
          nächsten Mal (wieder) begrüßen dürfen!
        </div>
      </div>
    </div>
  </ng-template>
</section>
