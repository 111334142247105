<mat-sidenav-container autosize class="default">
  <mat-sidenav #sidenav mode="side" color="primary"
               [opened]="screenWidth > 1400"
               [mode]="(screenWidth > 1400) ? 'side' : 'over'">
    <a [routerLink]="['/']" class="cnst-theme_brand-wrapper">
      <img class="cnst-theme_brand" src="assets/images/logo.png" />
    </a>
    <navigation color="primary" type="side"></navigation>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="cnst-theme_topbar">
      <div class="cnst-row align-center no-collapsing no-gutters">
        <div class="cnst-col auto">
          <button mat-button color="secondary" (click)="sidenav.toggle()"
            aria-label="Toggle sidenav">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div class="cnst-col adopt-width">
          <navigation type="top"></navigation>
        </div>
      </div>
    </div>
    <div class="cnst-theme_content-container">
      <article class="cnst-theme_article">
        <section class="cnst-theme_section">
          <header class="cnst-theme_section-header">
            <div class="cnst-row justify-space-between align-center">
              <div class="cnst-col auto">
                <div class="cnst-row gutters-small align-center">
                  <div class="cnst-col">
                    <h1 class="mat-title cnst-theme_section-title">
                      Pfingstschießen der Kgl. priv. SG 1834 Floß
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </section>
        <router-outlet></router-outlet>
      </article>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
