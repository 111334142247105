import { permissionsList } from '@config/permissions';
import { NavigationItem } from '@saikin/navigation/navigation.model';

export const topnav: Array<NavigationItem> = [];

export const sidenav: Array<NavigationItem> = [
  {
    label: '',
    isHeadline: true,
    children: [
      {
        label: 'Einladung',
        link: '/home',
      },
      {
        label: 'Anmeldung',
        link: '/anmeldung',
      },
      {
        label: 'Ergebnisse',
        link: '/ergebnisse',
      },
    ]
  },
];
