<ng-container *ngIf="navigationItems">
  <div class="navigation {{ type }} {{ user?.role }}">
    <ng-container *ngIf="type === 'side'">
      <mat-nav-list>
        <div class="cnst-row">
          <div class="cnst-col" style="text-align: right">
            <button mat-icon-button
                    (click)="closable ? sidenav.close() : null">
              <mat-icon>menu_open</mat-icon>
            </button>
          </div>
        </div>

        <ng-container *ngTemplateOutlet="tplAccountMenu"></ng-container>

        <ng-container *ngFor="let item of navigationItems; trackBy: trackByFn">
          <ng-container *ngIf="item.isHeadline || item.children.length > 0">
            <h5 class="mat-caption" *ngIf="item.isHeadline">{{ item.label }}</h5>
            <ng-container *ngFor="let child of item.children">
              <mat-expansion-panel
                  class="cnst-exp-panel cnst-exp-panel-nav mat-elevation-z0"
                  *ngIf="child.children && child.children.length > 0"
                  #rla="routerLinkActive"
                  routerLinkActive="active-link"
                  [expanded]="rla.isActive">
                <ng-container *ngIf="child.additionalLinks">
                  <span *ngFor="let link of child.additionalLinks"
                        style="display: none" [routerLink]="link"
                        (click)="closable ? sidenav.close() : null"
                  ></span>
                </ng-container>
                <mat-expansion-panel-header class="cnst-exp-panel_header">
                  <mat-panel-title class="cnst-exp-panel_title">
                    {{ child.label }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngIf="child.children && child.children.length > 0">
                  <mat-nav-list>
                    <a mat-list-item *ngFor="let subitem of child.children"
                       (click)="closable ? sidenav.close() : null"
                       [routerLink]="subitem.link"
                       [routerLinkActive]="['active']">
                      {{ subitem.label }}
                    </a>
                  </mat-nav-list>
                </ng-container>
              </mat-expansion-panel>
              <ng-container *ngIf="!child.children">
                <a mat-list-item
                    [routerLink]="child.link"
                    [routerLinkActive]="['active']"
                    (click)="closable ? sidenav.close() : null">
                  {{ child.label }}
                </a>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-nav-list>
    </ng-container>

    <ng-container *ngIf="type === 'top'">
      <mat-nav-list>
        <ng-content></ng-content>

        <ng-container *ngIf="screenWidth >= 850;">
          <ng-container *ngTemplateOutlet="tplTopMenuList; context: true"
          ></ng-container>
        </ng-container>
        <ng-container *ngIf="screenWidth < 850 && topNav && topNav.length > 0">
          <a [matMenuTriggerFor]="topMenuList" xPosition="before"
             class="menu-trigger">
            <mat-icon>menu</mat-icon>
            <span>Menü</span>
          </a>
          <mat-menu #topMenuList="matMenu" xPosition="before">
            <ng-container *ngTemplateOutlet="tplTopMenuList; context: false"
            ></ng-container>
          </mat-menu>
        </ng-container>

        <ng-container *ngTemplateOutlet="tplAccountMenu"></ng-container>
      </mat-nav-list>
    </ng-container>
  </div>
</ng-container>


<ng-template #tplAccountMenu>
  <div class="account-menu" *ngIf="showAccountMenu" style="order: 100;">
    {{ user.firstName }} {{ user.lastName }}
    <button mat-icon-button [matMenuTriggerFor]="accountMenu">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #accountMenu="matMenu" xPosition="before">
      <a mat-menu-item [routerLink]="['/home']">
        Einstellungen
      </a>

      <a mat-menu-item (click)="logout.emit()">
        Ausloggen
      </a>
    </mat-menu>
  </div>
</ng-template>

<ng-template #tplTopMenuList let-data="let-inline">
  <ng-container *ngFor="let item of navigationItems; trackBy: trackByFn">
    <a *ngIf="inline && (!item.children || item.children.length === 0)"
       mat-list-item
       [routerLink]="item.link">
      {{ item.label }}
    </a>
    <a *ngIf="!inline && (!item.children || item.children.length === 0)"
       mat-menu-item
       [routerLink]="item.link">
      {{ item.label }}
    </a>

    <a *ngIf="inline && (item.children && item.children.length > 0)"
       mat-list-item
       [matMenuTriggerFor]="topMenu">
      {{ item.label }}
    </a>
    <a *ngIf="!inline && (item.children && item.children.length > 0)"
       mat-menu-item
       [matMenuTriggerFor]="topMenu">
      {{ item.label }}
    </a>
    <mat-menu #topMenu="matMenu" xPosition="before">
      <a mat-menu-item *ngFor="let child of item.children"
         [routerLink]="child.link">
        {{ child.label }}
      </a>
    </mat-menu>
  </ng-container>
</ng-template>
