import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumberRange'
})
export class FormatNumberRange implements PipeTransform
{
  public transform(value: any, format: string): string
  {
    return this.formatDate(format)
      .replace(/(?!\\)N/g, value.toString().padStart(6, '0'))
      .replace(/\\/g, '');
  }

  private formatDate(format: string): string
  {
    const date = new Date();
    const _padStart = (value) => value.toString().padStart(2, '0');

    return format
      .replace(/(?!\\)Y/g, '' + date.getFullYear())
      .replace(/(?!\\)y/g, ('' + date.getFullYear()).substr(2, 2))
      .replace(/(?!\\)M/g, _padStart('' + (date.getMonth() + 1)))
      .replace(/(?!\\)m/g, '' + (date.getMonth() + 1));
  }
}
