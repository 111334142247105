<section class="cnst-theme_section">
  <div class="cnst-theme_section-header">
    <div class="cnst-row justify-space-between align-center">
      <div class="cnst-col auto">
        <h2 class="mat-title cnst-theme_section-title">
          Ergebnisse
        </h2>
      </div>
    </div>
  </div>

  <div class="cnst-theme_section-body">
    <h2 class="mat-subheading cnst-theme_section-body-title">
      Ergebnisse "Pfingstschießen 2024"
    </h2>

    <div class="cnst-row">
      <div class="cnst-col auto">
        <a mat-raised-button color="primary"
           href="{{pdfFileUrlsPrefix}}/ergebnisse-einzel.pdf"
           target="_blank"
        >
          Einzelergebnisse
        </a>
      </div>
      <div class="cnst-col auto">
        <a mat-raised-button color="primary"
           href="{{pdfFileUrlsPrefix}}/ergebnisse-mannschaft.pdf"
           target="_blank"
        >
          Mannschaftsergebnisse
        </a>
      </div>
      <div class="cnst-col auto">
        <a mat-raised-button color="primary"
           href="{{pdfFileUrlsPrefix}}/ergebnisse-glueck.pdf"
           target="_blank"
        >
          Glücksschießen
        </a>
      </div>
    </div>
  </div>
</section>

<section class="cnst-theme_section">
  <div class="cnst-theme_section-body">
    <h2 class="mat-subheading cnst-theme_section-body-title">
      Ergebnisse der letzten Jahre
    </h2>

    <form ng-submit="startSearch()">
      <div class="cnst-row">
        <div class="cnst-col adopt-width">
          <mat-form-field theme="control"
                          appearance="outline" floatLabel="always">
            <mat-label>Manschaffts-Name</mat-label>
            <input matInput #search placeholder="Mannschaft" [disabled]="true"/>
          </mat-form-field>
        </div>
        <div class="cnst-col auto">
          <button type="submit" mat-raised-button color="primary"
                  (click)="startSearch()"
                  [disabled]="1 === 1 || search.value.length < 3 ||
                              search.value === lastSearch"
          >
            Suchen
          </button>
        </div>
      </div>
      <div class="cnst-row">
        <div class="cnst-col adopt-width">
          <i>Die Ergebnis-Suche wird derzeit überarbeitet.</i>
        </div>
      </div>
    </form>
  </div>

  <div class="cnst-theme_section-body">
    <div class="results" *ngIf="teamEntries && teamEntries.data.length > 0">
      <h2>Mannschaften</h2>
      <div class="mat-elevation-z8">
        <mat-table [dataSource]="teamEntries">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.name }} {{ element.subteam }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let element">
              <team-details [instance]="element.element"></team-details>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="['name']"></mat-header-row>

          <mat-row *matRowDef="let row; columns: ['name'];"
                   matRipple
                   class="element-row"
                   [class.expanded]="expandedElement == row"
                   (click)="expandedElement = expandedElement === row
                          ? undefined : row"
          ></mat-row>

          <mat-row *matRowDef="let row; columns: ['expandedDetail'];
                               when: isExpansionDetailRow"
                   [@detailExpand]="row.element == expandedElement
                                    ? 'expanded' : 'collapsed'"
                   style="overflow: hidden">
          </mat-row>
        </mat-table>
      </div>
    </div>

    <div class="results"
         *ngIf="marksmanEntries && marksmanEntries.data.length > 0">
      <h2>Einzelschützen</h2>
      <div class="mat-elevation-z8">
        <mat-table [dataSource]="marksmanEntries">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.prename }} {{ element.surname }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let element">
              <marksman-details [instance]="element.element"></marksman-details>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="['name']"></mat-header-row>

          <mat-row *matRowDef="let row; columns: ['name'];"
                   matRipple
                   class="element-row"
                   [class.expanded]="expandedElement == row"
                   (click)="expandedElement = expandedElement === row
                          ? undefined : row"
          ></mat-row>

          <mat-row *matRowDef="let row; columns: ['expandedDetail'];
                               when: isExpansionDetailRow"
                   [@detailExpand]="row.element == expandedElement
                                    ? 'expanded' : 'collapsed'"
                   style="overflow: hidden">
          </mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</section>
