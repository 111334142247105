import { DatePipe } from '@angular/common';
import { MatDatepicker } from '@angular/material/datepicker';

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
         ViewChild
} from '@angular/core';


@Component({
  selector: 'cnst-datepicker',
  templateUrl: './cnst-datepicker.component.html',
})
export class CnstMatDatepickerComponent implements OnChanges
{
  @Input() label = '';
  @Input() mode = 'date';
  @Input() withSuffix = false;

  @Input() display = 'dd.MM.yyyy';
  @Input() output = 'yyyy-MM-dd';
  @Input() disabled = false;
  @Input() minuteInterval = 1;
  @Input() minuteIntervalRound: string = undefined;
  @Input() yearMonthOnly: boolean = false;

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('datePicker') datePickerElement = MatDatepicker;

  public _value: any = { display: undefined, picker: undefined, formatted: undefined };

  constructor(protected datePipe: DatePipe) { }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (changes['value'] && changes['value'].currentValue) {
      if (this.mode === 'date-time') {
        this.display = this.display + ' HH:mm';
      }

      this._value.formatted = changes['value'].currentValue;

      if (this.mode === 'time') {
        this._value.display = changes['value'].currentValue;
        this._value.picker = changes['value'].currentValue;
      }
      else {
        this._value.display =
          this.formatDate(changes['value'].currentValue, this.display);
        this._value.picker = new Date(this._value.formatted);
      }
    }
  }

  public emit(): void
  {
    this.valueChange.emit(this._value.formatted);
  }

  public manualInput(value: any): void
  {
    if (value.length === 10) {
      const parts = value.split('.').map(p => Number(p));
      if (parts.length === 3) {
        const date = new Date(parts[2], parts[1] - 1, parts[0]);

        if (date.getDate() === parts[0] &&
            date.getMonth() === parts[1] - 1 &&
            date.getFullYear() === parts[2])
        {
          this._value.display = this.formatDate(date, this.display);
          this._value.formatted = this.formatDate(date, this.output);
          this._value.picker = date;
          this.emit();
        }
      }
    }
  }

  public pickerSelect(value: any): void
  {
    if (this.mode === 'time') {
      value = this.formatDate(this._value.picker ?? new Date(), 'HH:mm');
      value = this.roundMinutes(value);
      this._value.display = value;
      this._value.formatted = value;
    }
    else {
      this._value.display = this.formatDate(value, this.display);
      this._value.formatted = this.formatDate(value, this.output);
    }
    this.emit();
  }

  public formatDate(date: any, format: string): string
  {
    return this.datePipe.transform(date, format);
  }

  public onMonthSelected(event: any)
  {
    if (this.yearMonthOnly) {
      (<any> this.datePickerElement).close();
      this.pickerSelect(event);
    }
  }

  // TODO: temp. Workaround *hust*
  private roundMinutes(value: string): string
  {
    if (!this.minuteIntervalRound) {
      return value;
    }


    let minutes = ['00', '15', '30', '45'];
    if (this.minuteIntervalRound === 'floor') {
      minutes = minutes.reverse();
    }

    const _value = value.split(':');
    for (const min of minutes) {
      if (Number(_value[1]) > Number(min)) {
        value = _value[0] + ':' + min;
        return value;
      }
    }
  }
}
