import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';

import { DeleteDialog } from '@saikin/dialogs/delete-dialog.component';
import { BaseComponent } from '@saikin/components/_base/base.component';
import { SaikinBaseModel } from '@saikin/saikin.model';
import { executeWithNotification } from '@saikin/util';

export class EDIT_OPERATION { // eslint-disable-line
  public static readonly CREATE: string = 'create';
  public static readonly UPDATE: string = 'update';
  public static readonly DELETE: string = 'delete';
}

@Component({
  selector: 'abstract-cnst-edit',
  template: ''
})
export abstract class AbstractEditComponent<T> extends BaseComponent
                                               implements OnInit
{
  public instance: SaikinBaseModel = undefined;
  public pInstance: Promise<T>;

  protected subscriptions: Array<Subscription> = [];

  protected abstract loadInstance(): Promise<void>;
  protected abstract resetInstance(): void;

  protected redirectToOverview(): void
  {
    // nothing here
  }

  protected async createInstance(): Promise<boolean> { return false; }
  protected async updateInstance(): Promise<boolean> { return false; }
  protected async deleteInstance(): Promise<boolean> { return false; }

  protected openDeleteDialog(): void
  {
    const dialogRef = this.dialog.open(DeleteDialog, {
      data: {
        id: this.instance.id,
        name: this.instance.toString(),
        type: this.instance._modelLabel,
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result !== undefined) {
        this.deleteInstance();
      }
    });
  }

  protected async executeInstanceOperation(
    callback: any, operation: string): Promise<boolean>
  {
    let success = this.instance._modelLabel + ' erfolgreich ';
    let error = ' von "' + this.instance.toString() + '" fehlgeschlagen';

    switch (operation) {
      case EDIT_OPERATION.CREATE:
        success = success + 'angelegt';
        error = 'Anlegen' + error;
        break;
      case EDIT_OPERATION.UPDATE:
        success = success + 'bearbeitet';
        error = 'Bearbeiten' + error;
        break;
      case EDIT_OPERATION.DELETE:
        success = success + 'gelöscht';
        error = 'Löschung' + error;
        break;
      default:
        success = 'Änderung erfolgreich gespeichert';
        error = 'Es ist ein Fehler aufgetreten';
    }

    return executeWithNotification(this.snackBar, {
      callback, success, error
    });
  }

  public async ngOnInit(): Promise<void>
  {
    this.loading = false;
    this.loadInstance().then(() => {
      this.loading = false;
    });
  }

  public createInstanceRepeatFN = async (): Promise<void> => {
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    this.createInstance().then(success => {
      if (success) {
        this.loading = true;
        this.resetInstance();
        delay(700).then(() => { this.loading = false; });
      }
    });
  };

  public createInstanceReturnFN = async (): Promise<void> => {
    this.createInstance().then(success => {
      if (success) {
        this.redirectToOverview();
      }
    });
  };

  public createInstanceFN = async (): Promise<void> => {
    await this.createInstance();
  };
  public updateInstanceFN = async (): Promise<void> => {
    await this.updateInstance();
  };
  public deleteInstanceFN = async (): Promise<void> => {
    await this.deleteInstance();
  };
  public deleteInstanceWithDialogFN = (): void => {
    this.openDeleteDialog();
  };

  public ngOnDestroy(): void
  {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
