import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild,
         Input
} from '@angular/core';

import { fromEvent } from 'rxjs';
import { tap, switchMap, takeUntil, finalize } from 'rxjs/operators';

import { dataUriToBlob } from '@saikin/util';

interface Bounds
{
  x: number;
  y: number;
}

@Component({
  selector: 'cnst-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class CnstSignaturePadComponent implements AfterViewInit
{
  @Output() signatureDone: EventEmitter<any> = new EventEmitter<any>();
  @Input() showButtons = true;

  @ViewChild('signaturePad') signaturePad: ElementRef;
  private context: CanvasRenderingContext2D;
  private isDrawing = false;
  public isDone = false;
  public imgSrc: string;
  public coords: any;

  public ngAfterViewInit(): void
  {
    this.context = this.signaturePad.nativeElement.getContext('2d');
    const resizeCanvas = () => {
      const parent = this.signaturePad.nativeElement.parentElement;
      this.signaturePad.nativeElement.width =
        parent.getBoundingClientRect().width;
    };

    window.addEventListener('resize', resizeCanvas, false);

    const penDown = fromEvent(this.signaturePad.nativeElement, 'mousedown');
    const penMove = fromEvent(this.signaturePad.nativeElement, 'mousemove');
    const penUp = fromEvent(window, 'mouseup');
    this.signaturePad.nativeElement
        .addEventListener('touchend', this.endDrawing.bind(this));

    resizeCanvas();

    penDown.pipe(
      tap((event: Event) => this.startDrawing(event)),
      switchMap(() => penMove.pipe(
        tap((event: Event) => this.drawSignature(event)),
        takeUntil(penUp),
        finalize(() => this.endDrawing())
      ))
    ).subscribe();
  }

  public endDrawing(e?: Event): void
  {
    this.isDrawing = false;
    if (e) {
      e.preventDefault();
    }
    this.context.closePath();
  }

  public startDrawing(e: Event): void
  {
    this.isDrawing = true;

    const coords = this.relativeCoords(e);

    this.context.beginPath();
    this.context.lineWidth = 3;
    this.context.lineJoin = 'round';

    this.context.moveTo(coords.x, coords.y);
  }

  public drawSignature(e: Event): void
  {
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event): Bounds
  {
    const bounds = event.target.getBoundingClientRect();
    let x = 0;
    let y = 0;

    if (event instanceof TouchEvent) {
      const touch = event.touches[0] || event.changedTouches[0];
      x = touch.pageX - bounds.left;
      y = touch.pageY - bounds.top;
    }
    else {
      x = (<any> event).offsetX;
      y = (<any> event).offsetY;
      //~ x = event.clientX - bounds.left;
      //~ y = event.clientY - bounds.top;
    }

    event.preventDefault();
    return { x, y };
  }


  public clear(): void
  {
    const element = this.signaturePad.nativeElement;
    this.context.clearRect(0, 0, element.width, element.height);
    this.context.beginPath();
  }

  public save(): void
  {
    const data = this.signaturePad.nativeElement.toDataURL('image/png');
    this.imgSrc = data;
    this.signatureDone.emit(data);
    this.isDone = true;
  }

  public getSignature(): any
  {
    const data = this.signaturePad.nativeElement.toDataURL('image/png');
    return dataUriToBlob(data);
  }
}
