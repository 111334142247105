import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit,
         Output, SimpleChanges, ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'cnst-auto-combobox',
  templateUrl: './auto-combobox.component.html',
})
export class CnstAutoComboboxComponent implements OnChanges, OnInit
{
  @Input() allowNewInput = false;
  @Input() data: Array<any>;
  @Input() displayField: string | Array<string> = undefined;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() deleteSuffix = false;

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleted: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchField') public searchField: ElementRef;

  public dropdownControl: FormControl = new FormControl();
  public filteredOptions: Observable<string[]>;

  private entries: Array<any> = [];
  private lastSelection: any = undefined;

  public ngOnInit(): void
  {
    this.filteredOptions =
      this.dropdownControl.valueChanges.pipe(
        startWith(''),
        filter(x => typeof x === 'string'),
        map(value => typeof value === 'string' ? value : this.toString(value)),
        map(name => name
          ? this.entries.filter(o => o.display.toLowerCase()
            .indexOf(name.toLowerCase()) > -1)
          : this.entries.slice()
        )
      );
  }

  public clear(): void
  {
    this.value = undefined;
    this.dropdownControl.setValue(undefined);
    this.searchField.nativeElement.value = '';
    this.emitSelection(undefined, undefined);
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (this.data) {
      this.entries = this.data
        .map(value => ({ value, display: this.toString(value) }));
    }
    if (changes['value']) {
      this.value = changes['value'].currentValue;
      this.dropdownControl.setValue(
        this.toString(this.value), { emitEvent: true });
    }
    this.displayList();
  }

  private returnNewInput(): void
  {
    const input = this.searchField.nativeElement.value;

    if (this.value && typeof this.value !== 'string') {
      this.value.id = undefined; // TODO: ungenau
      this.value.label = input; // TODO: ungenau
      this.valueChange.emit(this.value);
    }
    else {
      this.valueChange.emit(input);
    }
  }

  public focusOut()
  {
    const input = this.searchField.nativeElement.value;
    const idx = this.data.map(d => this.toString(d)).indexOf(input.trim());

    if (idx > -1) {
      const selection = this.data[idx];
      this.emitSelection(selection);
    }
    else if (this.allowNewInput) {
      this.returnNewInput();
    }
    else {
      this.emitSelection();
    }
  }

  public emitSelection(selection?: any, event?: any): void
  {
    if ((selection !== undefined || this.lastSelection !== undefined) &&
        selection !== this.lastSelection) {
      if (!event || (event && event.isUserInput)) {
        this.value = selection;
        this.valueChange.emit(selection);
        this.selected.emit(selection);
        this.searchField.nativeElement.value = this.toString(selection);
        this.lastSelection = selection;
      }
    }
  }

  public displayList(): void
  {
    const value = !this.dropdownControl.value ?
      '' : this.dropdownControl.value;
    this.dropdownControl.setValue(value, { emitEvent: true });
  }

  public toString(object: any): string
  {
    if (object && typeof object === 'object') {
      return this.displayField === undefined
        ? object.toString()
        : (
          typeof this.displayField === 'string'
            ? (
              typeof object[this.displayField] === 'function'
                ? object[this.displayField]()
                : object[this.displayField]
            )
            : (this.displayField instanceof Array
              ? this.displayField.map(d => object[d]).join(' ')
              : '')
        );
    }
    else if (typeof object === 'string') {
      return object;
    }
    return '';
  }
}
