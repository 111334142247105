import { Directive, ElementRef, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: 'cnst-carousel-container',
})
export class CnstCarouselContainer
{
  private startX: number;
  private startY: number;
  private startTime: number;

  private threshold = 150;
  private timeout = 200;

  public swiped: BehaviorSubject<string> =
    new BehaviorSubject<string>(undefined);

  constructor(public elementRef: ElementRef) {}

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  public onSwipeStart(event): void
  {
    if (event.changedTouches) {
      const touch = event.changedTouches[0];
      this.startX = touch.pageX;
      this.startY = touch.pageY;
      this.startTime = new Date().getTime();
    }
  }

  @HostListener('touchend', ['$event'])
  @HostListener('mouseup', ['$event'])
  public onSwipeEnd(event): void
  {
    if (event.changedTouches) {
      const touch = event.changedTouches[0];

      const timeout = (new Date().getTime() - this.startTime) <= this.timeout;
      const scrolled = Math.abs(this.startY - touch.pageY) > 100;
      const distance = touch.pageX - this.startX;

      if (!timeout && !scrolled && Math.abs(distance) > this.threshold) {
        this.swiped.next(distance < 0 ? 'right' : 'left');
        event.preventDefault();
      }
    }
  }

  public getHeight(): number
  {
    return this.elementRef.nativeElement.offsetHeight;
  }

  public show(direction: string): void
  {
    this.elementRef.nativeElement.style.setProperty('display', 'inherit');
    this.setClass('slide-in-from-' + direction);
  }

  public hide(direction?: string): void
  {
    this.setClass('slide-out-to-' + direction);
  }

  private setClass(className: string): void
  {
    const availableClasses = [ 'slide-in-from-left', 'slide-in-from-right',
                               'slide-out-to-left', 'slide-out-to-right'];
    availableClasses.map((c) => {
      if (this.elementRef.nativeElement.classList.contains(c)) {
        this.elementRef.nativeElement.classList.remove(c);
      }
    });
    this.elementRef.nativeElement.classList.add(className);
  }
}
