import { Component, EventEmitter, HostListener, Input, OnChanges, Output
} from '@angular/core';

import { User } from '@config/models';
import { sidenav, topnav } from '@config/navigation';
import { deepCopy } from '@saikin/util';

import { AuthService } from '@saikin/auth/auth.service';
import { NavigationItem } from './navigation.model';

@Component({
  selector: 'navigation',
  styleUrls: ['./navigation.component.scss'],
  templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnChanges
{
  @Input() user: User;
  @Input() type = 'side';
  @Input() themeColor = '';
  @Input() sidenav: any = null;
  @Input() closable = false;

  @Input() showAccountMenu = false;
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  public screenWidth: number;
  public navigationItems: Array<NavigationItem> = [];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private authService: AuthService,
  )
  {

  }

  public async ngOnChanges(): Promise<void>
  {
    this.screenWidth = window.innerWidth;
    if (!this.user) {
      this.user = this.authService.currentUser;
    }
    this.navigationItems = this.getItems();
  }

  public trackByFn(index: number, item: NavigationItem): string
  {
    return item.label;
  }

  private getItems(): Array<NavigationItem>
  {
    //~ if (!this.user) {
      //~ this.user = this.authService.currentUser;
    //~ }
    let navItems: Array<NavigationItem> = [];

    switch (this.type) {
      case 'side':
        navItems = deepCopy(sidenav);
        break;
      case 'top':
        navItems = deepCopy(topnav);
        break;
      default:
        throw Error(this.type + ': no such navigation found');
    }

    return navItems
      .map(item => this.filterNavigationItems(item))
      .filter(ni => ni);
  }

  private filterNavigationItems(item: any): any
  {
    if (item.children) {
      console.log(item);
      item.children = item.children
        .map(ni => this.filterNavigationItems(ni))
        .filter(ni => ni);
      if (item.children.length === 0) {
        return undefined;
      }
    }

    const permissions =
      !item.permissions ||
      item.permissions.map(p => this.authService.canDo(p)).indexOf(true) > -1;
    const roles = !item.users || item.users.indexOf(this.user.role) > -1;

    return permissions && roles ? item : undefined;
  }
}
