<ng-container *ngIf="!inline">
  <div class="cnst-theme_section-footer no-margin">
    <ng-container  *ngTemplateOutlet="buttons"
    ><ng-content></ng-content></ng-container>
  </div>
  <mat-progress-bar *ngIf="waitForIt && waitForIt.state"
                    color="accent" mode="indeterminate">
  </mat-progress-bar>
</ng-container>

<ng-container *ngIf="inline">
  <ng-container  *ngTemplateOutlet="buttons"
  ><ng-content></ng-content></ng-container>
</ng-container>

<ng-template #buttons let-lessonsCounter="estimate">
  <mat-action-list class="control-group">
    <button *ngIf="btnEdit && exists"
            mat-flat-button color="primary" [disabled]="waitForIt.state"
            [attr.data-action]="'button-section-edit'"
            (click)="execute(btnEdit)"
    >{{ (btnEditText ? btnEditText : (prefix + ' bearbeiten')) }}</button>

    <button *ngIf="btnDelete && exists"
            mat-stroked-button color="warn" [disabled]="waitForIt.state"
            [attr.data-action]="'button-section-delete'"
            (click)="execute(btnDelete)"
    >{{ prefix }} löschen</button>

    <button *ngIf="btnAddReturn && !exists"
            mat-flat-button color="primary" [disabled]="waitForIt.state"
            [attr.data-action]="'button-section-createAndBack'"
            (click)="execute(btnAddReturn)"
    >Speichern und zurück</button>

    <button *ngIf="btnAddRepeat && !exists"
            mat-stroked-button [disabled]="waitForIt.state"
            [attr.data-action]="'button-section-createAndRepeat'"
            (click)="execute(btnAddRepeat)"
    >Speichern</button>

    <button *ngIf="btnAdd && !exists"
            mat-flat-button color="primary" [disabled]="waitForIt.state"
            [attr.data-action]="'button-section-create'"
            (click)="execute(btnAdd)"
    >{{ prefix }} anlegen</button>

    <button *ngIf="btnReset && !exists"
            mat-stroked-button
            [attr.data-action]="'button-section-empty'"
            (click)="execute(btnReset)"
    >{{ prefix }} leeren</button>

    <ng-content></ng-content>
  </mat-action-list>
</ng-template>
